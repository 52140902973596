package com.kelimesoft.cowmaster.pages.cowdetails

import androidx.compose.runtime.Composable
import com.kelimesoft.cowmaster.components.VDivider
import com.kelimesoft.cowmaster.models.AppColors
import com.kelimesoft.cowmaster.models.HerdItem
import com.kelimesoft.cowmaster.pages.summary.HerdListView
import com.kelimesoft.cowmaster.styles.DetailInfoDataStyle
import com.kelimesoft.cowmaster.styles.DetailInfoTitleStyle
import com.kelimesoft.cowmaster.utils.tr
import com.kelimesoft.cowmaster.utils.yasiniBul
import com.kelimesoft.cowmaster.viewmodals.CowDetailViewModel
import com.varabyte.kobweb.compose.css.Cursor

import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.core.App
import com.varabyte.kobweb.silk.components.icons.fa.FaLayerGroup
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.style.toAttrs
import com.varabyte.kobweb.silk.components.style.toModifier
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import kotlin.js.Date

@Composable
fun CowGeneralInfo(detailVM: CowDetailViewModel, onEdit: () -> Unit, onHerd: () -> Unit) {

    detailVM.cowDetay?.let { cowDetay ->
        CowDetayCard(
            title = "Hayvan Detay",
            button =
            {
                DetailsButton("detay_edit".tr(), onEdit)
            },

            ) {
            InfoLine("detay_kupe".tr(), cowDetay.kupe)
            InfoLine("detay_name".tr(), cowDetay.name)
            InfoLine("detay_gender".tr(), if (cowDetay.gender == 1) "shared_male".tr() else "shared_female".tr())
            InfoLine("detay_birth".tr(), Date(cowDetay.birth).toLocaleDateString())
            InfoLine("detay_irk".tr(), cowDetay.breed)
            InfoLine("detay_yas".tr(), cowDetay.birth.yasiniBul())
            InfoLine("detay_anne".tr(), cowDetay.dam)
            InfoLine("detay_baba".tr(), cowDetay.sire)
            InfoLine("detay_notes".tr(), cowDetay.notes)

            Row(
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.SpaceAround,
                modifier = Modifier.fillMaxWidth()
            ) {
                CowHerdPicker(detailVM.listCow?.herd, onClick = {
                    onHerd()
                })
                //CowRasyonPicker()
                CowRasyonPicker(detailVM.listCow?.herd, onClick = {

                })
            }
        }

    }

}

@Composable
private fun InfoLine(title: String, value: String) {
    Column(
        modifier = Modifier.fillMaxWidth()
            .padding(2.px)
    ) {
        Row(
            modifier = Modifier.fillMaxWidth(),
            verticalAlignment = Alignment.CenterVertically,
            horizontalArrangement = Arrangement.SpaceBetween
        ) {
            Span(
                attrs = DetailInfoTitleStyle.toAttrs()
            ) {
                Text(title)
            }

            Span(
                attrs = DetailInfoDataStyle
                    .toModifier()
                    .fillMaxWidth(64.percent)
                    .toAttrs()
            ) {
                Span(
                    attrs = DetailInfoTitleStyle.toAttrs()
                ) {
                    Text(": ")
                }

                Text(value)
            }
        }
        VDivider()


    }
}

@Composable
fun CowHerdPicker(herd: HerdItem?, onClick: () -> Unit){
    Column(modifier = Modifier.fillMaxWidth()
        .borderRadius(r = 8.px)
        .padding(8.px)
        .backgroundColor(Colors.Gray.copyf(alpha = 0.2f))
        .cursor(Cursor.Pointer)
        .boxShadow(1.px, 1.px, 1.px, 1.px, color = Colors.Gray.copyf(alpha = 0.2f))
        .margin(right = 6.px, top = 4.px)
        .onClick{
            onClick()
        }

    ) {
        Row(
            modifier = Modifier.margin(leftRight = 1.px, bottom = 3.px),
            verticalAlignment = Alignment.CenterVertically) {
            FaLayerGroup(
                modifier = Modifier
                    .margin(right = 4.px)
                    .color(AppColors.Gray),
                size = IconSize.XS
            )
            Span(
                attrs = Modifier
                    .fontSize(10.px)
                    .color(AppColors.TextColor)
                    .toAttrs()
            ) {
                Text("Sürü / Padok")
            }

        }

        if (herd != null) {
            Row(verticalAlignment = Alignment.CenterVertically) {
                Box(
                    modifier = Modifier.size(17.px)
                        .borderRadius(r = 50.percent)
                        .opacity(80.percent)
                        .backgroundColor(AppColors.herdColorList[(herd.color) - 1])
                        .margin(right = 2.px, top = 1.px)
                        .padding(2.px)
                    ,
                    contentAlignment = Alignment.Center
                ) {
                    Span(
                        attrs = Modifier
                            .fontSize(10.px)
                            .color(Colors.White)
                            .toAttrs()
                    ) {
                        Text(herd.name.substring(0, 1))
                    }
                }

                Span(
                    attrs = Modifier
                        .fontSize(12.px)
                        .color(AppColors.TextColor)
                        .toAttrs()
                ) {
                    Text(herd.name)
                }
            }
        }else{
            Span(
                attrs = Modifier
                    .fontSize(12.px)
                    .color(Colors.DarkGray)
                    .toAttrs()
            ) {
                Text("Sürü Seçiniz")
            }
        }
    }

}

@Composable
fun CowRasyonPicker(herd: HerdItem?, onClick: () -> Unit){
    Column(modifier = Modifier.fillMaxWidth()
        .borderRadius(r = 8.px)
        .padding(8.px)
        .backgroundColor(Colors.Gray.copyf(alpha = 0.2f))
        .cursor(Cursor.Pointer)
        .boxShadow(1.px, 1.px, 1.px, 1.px, color = Colors.Gray.copyf(alpha = 0.2f))
        .margin(right = 6.px)

    ) {
        Row(verticalAlignment = Alignment.CenterVertically) {
            FaLayerGroup(
                modifier = Modifier
                    .margin(right = 4.px)
                    .color(AppColors.Gray),
                size = IconSize.XS
            )
            Span(
                attrs = Modifier
                    .fontSize(10.px)
                    .color(AppColors.TextColor)
                    .toAttrs()
            ) {
                Text("Sürü / Padok")
            }

        }

        if (herd != null) {
            Row(verticalAlignment = Alignment.CenterVertically) {
                Box(
                    modifier = Modifier.size(17.px)
                        .borderRadius(r = 50.percent)
                        .opacity(80.percent)
                        .backgroundColor(AppColors.herdColorList[(herd.color) - 1])
                        .margin(right = 2.px, top = 1.px)
                        .padding(2.px)
                    ,
                    contentAlignment = Alignment.Center
                ) {
                    Span(
                        attrs = Modifier
                            .fontSize(10.px)
                            .color(Colors.White)
                            .toAttrs()
                    ) {
                        Text(herd.name.substring(0, 1))
                    }
                }

                Span(
                    attrs = Modifier
                        .fontSize(12.px)
                        .color(AppColors.TextColor)
                        .toAttrs()
                ) {
                    Text(herd.name)
                }
            }
        }else{
            Span(
                attrs = Modifier
                    .fontSize(12.px)
                    .color(Colors.DarkGray)
                    .toAttrs()
            ) {
                Text("Sürü Seçiniz")
            }
        }
    }

}