package com.kelimesoft.cowmaster.pages

import androidx.compose.runtime.Composable
import com.kelimesoft.cowmaster.components.BottomNavigation
import com.kelimesoft.cowmaster.components.LeftPane
import com.kelimesoft.cowmaster.components.NoContentYet
import com.kelimesoft.cowmaster.models.Screens
import com.kelimesoft.cowmaster.styles.MainPageStyle
import com.kelimesoft.cowmaster.viewmodals.AppData
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint

@Page("/profil")
@Composable
fun ProfilePage() {
    AppData.appViewModel.activeScreens = Screens.Profile
    Router()
}

@Composable
fun MyProfile() {
    val breakpoint = rememberBreakpoint()
    Column(
        modifier = MainPageStyle.toModifier(),
        verticalArrangement = Arrangement.Top,
        horizontalAlignment = Alignment.CenterHorizontally) {
        Row(
            modifier = Modifier.fillMaxSize()
        ) {
            if (breakpoint >= Breakpoint.SM){
                LeftPane(breakpoint)
            }
            NoContentYet("Profil sayfası yakında etkinleştirilerilecektir, lütfen birkaç gün içinde tekrar kontrol ediniz.")

        }

        if (breakpoint < Breakpoint.SM){
            BottomNavigation(breakpoint)
        }

    }
}