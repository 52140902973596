package com.kelimesoft.cowmaster.pages.cowlist

import androidx.compose.runtime.*
import com.kelimesoft.cowmaster.components.*
import com.kelimesoft.cowmaster.models.AppColors
import com.kelimesoft.cowmaster.models.ErrorCow

import com.kelimesoft.cowmaster.network.ErrorListResponse
import com.kelimesoft.cowmaster.network.RestApi
import com.kelimesoft.cowmaster.styles.CowInputStyle
import com.kelimesoft.cowmaster.styles.MainButtonStyle
import com.kelimesoft.cowmaster.styles.TextButtonStyle
import com.kelimesoft.cowmaster.utils.AppStorage
import com.kelimesoft.cowmaster.utils.Sprintf
import com.kelimesoft.cowmaster.utils.dateToDbStr
import com.kelimesoft.cowmaster.utils.tr

import com.kelimesoft.cowmaster.components.AddNewHerd
import com.kelimesoft.cowmaster.components.AlertBox
import com.kelimesoft.cowmaster.components.AlertInfo

import com.kelimesoft.cowmaster.models.NewListCow
import com.kelimesoft.cowmaster.viewmodals.AppData
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.css.WhiteSpace
import com.varabyte.kobweb.compose.foundation.layout.*
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.attrsModifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.style.toModifier
import kotlinx.browser.document
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import kotlinx.serialization.json.Json
import org.jetbrains.compose.web.ExperimentalComposeWebApi
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.css.CSSColorValue
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.*
import org.w3c.dom.HTMLSelectElement
import kotlin.js.Date

@Composable
fun AddCattleList(onClose: () -> Unit) {
    val scope = rememberCoroutineScope()
    LaunchedEffect(true) {
        AppData.summaryViewModel.getHerdList()
    }

    var newCowList = remember { mutableStateListOf(NewListCow()) }
    var addNewHerd by remember { mutableStateOf(false) }
    var boxAlert: AlertInfo? by remember { mutableStateOf(null) }
    var showProgress by remember { mutableStateOf(false) }

    fun saveCattleList(){
        val saveList = arrayListOf<NewListCow>()
        val savedKupe = mutableSetOf<String>()
        newCowList.forEach { ncow ->
            if (savedKupe.contains(ncow.kupe)){
                ncow.errorText = "addlist_kupeerr".tr()
            }else{
                val err = ncow.checkNewCow()
                if (!err){
                    saveList.add(ncow)
                    savedKupe.add(ncow.kupe)
                }
            }

        }
        if(saveList.isNotEmpty()){
            var errorList = arrayListOf<ErrorCow>()
            showProgress = true
            val weaning = if (AppStorage.getBool(AppStorage.addWeaningNotif)) AppStorage.getInt(AppStorage.weaningDays) else 0
            scope.launch {
                RestApi.saveCowList(saveList.toList(), weaning)?.let { res ->
                    Json.decodeFromString<ErrorListResponse>(res).let { elist ->
                        if (elist.data != null) {
                            errorList = elist.data
                        }
                    }
                }
                val saved = saveList.size - errorList.size
                val failed = newCowList.size - saved
                errorList.forEach { ecow ->
                    val index = newCowList.indexOfFirst { it.hid == ecow.hid }
                    newCowList[index].errorText = ecow.error
                }
                newCowList.filter { it.errorText.isEmpty() }.forEach {
                    newCowList.remove(it)
                }
                if (saved > 0){
                    var alertText = "addlist_success".tr().Sprintf(saved)
                    if (failed > 0){
                        alertText +="addlist_fail".tr().Sprintf(failed)
                    }
                    boxAlert = AlertInfo("addlist_saved".tr().Sprintf(saved), alertText, "", "Tmm", onClose = {
                        boxAlert = null
                    }){
                        boxAlert = null
                    }
                    AppData.summaryViewModel.resetRequestCowsAdded()
                    AppData.cowListVM.getCowList()
                }
                showProgress = false
            }

        }
    }

    Column(
        modifier = Modifier.fillMaxWidth(95.percent)
            //.position(Position.Relative)
            .padding(4.px)
            .backgroundColor(Colors.White)
            .borderRadius(topLeft = 16.px, topRight = 16.px, bottomLeft = 6.px, bottomRight = 6.px)
            .boxShadow(2.px, (-1).px, 2.px, color = AppColors.Gray.copyf(0.5f))
            .minHeight(400.px)
            .maxHeight(760.px)
            .overflow(Overflow.Hidden)
            .maxWidth(1400.px)
            .minWidth(900.px),
        verticalArrangement = Arrangement.Top
    ) {
        DialogTitle("addlist_add".tr(), onClose)
        VDivider()

            Column(
                modifier = Modifier.fillMaxWidth()
                    .overflow { y(Overflow.Auto) },
                verticalArrangement = Arrangement.Top
            ) {
                Form(attrs = Modifier.fillMaxWidth()
                    .id("addlist-form")
                    .toAttrs()) {

                newCowList.forEachIndexed { index,  newCow ->
                    CowAddRow(newCow, addNewHerd = {
                        addNewHerd = true
                    }, onDelete = {
                        newCowList.removeAt(index)
                        newCowList = newCowList.toMutableStateList()
                    })
                }
            }
        }

        Spacer()
        Row(
            modifier = Modifier.fillMaxWidth()
                .backgroundColor(Colors.Gray.copyf(alpha = 0.1f))
                .padding(topBottom = 2.px, leftRight = 8.px),
            verticalAlignment = Alignment.CenterVertically,
        ) {
            Button(attrs = TextButtonStyle.toModifier()
                .color(AppColors.DarkRed.copyf(0.7f))
                .fontSize(16.px)
                .maxHeight(30.px)
                .onClick {
                    newCowList.add(NewListCow())
                }
                .toAttrs()
            ) {
                Text("addlist_new".tr())
            }

            Spacer()

            WeaningSetting()

            Button(attrs = MainButtonStyle.toModifier()
                .whiteSpace(WhiteSpace.NoWrap)
                .maxHeight(30.px)
                .fontSize(16.px)
                .margin(left = 10.px)
                .onClick {
                    saveCattleList()
                }
                .toAttrs()
            ) {
                Text("addlist_save".tr())
            }
        }
    }

    if (addNewHerd) {
        DialogView {
            AddNewHerd {
                addNewHerd = false
            }
        }
    }

    if (boxAlert != null) {
        DialogView {
            AlertBox(boxAlert!!)
        }
    }

    if(showProgress){
        DialogView {
            Progress()
        }
    }



}


@OptIn(ExperimentalComposeWebApi::class)
@Composable
fun CowAddRow(newcow: NewListCow, onDelete: () -> Unit, addNewHerd: () -> Unit) {
    Box(modifier = Modifier.fillMaxWidth(),
        contentAlignment = Alignment.BottomStart
        ) {
        Row(
            modifier = Modifier.fillMaxWidth(),
            horizontalArrangement = Arrangement.SpaceEvenly,
            verticalAlignment = Alignment.CenterVertically
        ) {
            TextField("kupe", "addlist_kupe".tr(), true) { value ->
                newcow.kupe = value
                newcow.errorText = ""
            }
            TextField("isim", "addlist_ismi".tr(), ) { value ->
                newcow.name = value
            }

            GenderField(newcow.hid) { value ->
                newcow.gender = value
            }
            DateField("addlist_dtrh".tr()) { value ->
                newcow.dogum = value
                newcow.errorText = ""
            }
            TextField("irki","addlist_irk".tr()) { value ->
                newcow.breed = value
            }
            TextField("anne","addlist_anne".tr()) { value ->
                newcow.dam = value
            }
            TextField("baba", "addlist_baba".tr()) { value ->
                newcow.sire = value
            }
            /*
            HerdField(newcow.uid, addNewHerd = addNewHerd) {
                newcow.herd = it.toLong()
            }
             */
            /*

            Button(attrs = TextButtonStyle.toModifier()
                .onClick {
                    it.preventDefault()
                    onDelete()
                }
                .color(AppColors.DarkRed.copyf(alpha = 0.9f))
                .toAttrs()) {
                FaTrash(
                    size = IconSize.SM)
            }*/


        }

        if (newcow.errorText.isNotEmpty()){
            ErrorText(newcow.errorText)
        }
    }



}

@Composable
private fun TextField(name: String, placeholder: String = "", required: Boolean = false, onChange: (String) -> Unit) {
    Input(
        type = InputType.Text,
        attrs = CowInputStyle.toModifier()
            .maxHeight(35.px)
            .attrsModifier {
                if (name == "irki"){
                    attr("autocomplete", "on")
                }
                attr("name", name)
                attr("placeholder", placeholder)
                attr("required", if (required) "true" else "false")
            }
            .toAttrs()
            {
                onInput {
                    onChange(it.value)
                }
            }
    )

}

@Composable
private fun GenderField(hid: Long, onChange: (Int) -> Unit) {
    Row(
        modifier = CowInputStyle.toModifier()
            .maxHeight(35.px)
            .maxWidth(170.px),
        verticalAlignment = Alignment.CenterVertically,
        horizontalArrangement = Arrangement.SpaceBetween
    ) {
        Row(verticalAlignment = Alignment.CenterVertically) {
            Input(
                type = InputType.Text,
                attrs = Modifier
                    .margin(right = 4.px)
                    .size(16.px)
                    .attrsModifier {
                        attr("type", "radio")
                        attr("name", "gender$hid")
                        attr("value", "0")
                        attr("checked", "checked")
                    }
                    .toAttrs()
                    {
                        onInput {
                            onChange(it.value.toInt())
                        }
                    }
            )
            Label(
                attrs = Modifier
                    .textAlign(TextAlign.Start)
                    .fontSize(15.px)
                    .margin(right = 10.px)
                    .toAttrs()
            ) {
                Text("shared_female".tr())
            }
        }

        Row(verticalAlignment = Alignment.CenterVertically) {
            Input(
                type = InputType.Text,
                attrs = Modifier
                    .size(16.px)
                    .margin(right = 4.px)
                    .attrsModifier {
                        attr("type", "radio")
                        attr("name", "gender$hid")
                        attr("value", "1")
                    }
                    .toAttrs()
                    {
                        onInput {
                            onChange(it.value.toInt())
                        }
                    }
            )
            Label(
                attrs = Modifier
                    .textAlign(TextAlign.Start)
                    .fontSize(15.px)
                    .toAttrs()
            ) {
                Text("shared_male".tr())
            }
        }


    }
}

@Composable
private fun DateField(placeholder: String = "", required: Boolean = false, onChange: (String) -> Unit) {
    Input(

        type = InputType.Date,
        attrs = CowInputStyle.toModifier()
            .maxHeight(35.px)
            .maxWidth(170.px)
            .padding(7.px)
            .attrsModifier {
                //attr("type", "text")
                attr("placeholder", placeholder)
                attr("required", if (required) "true" else "false")
                attr("max", Date().dateToDbStr())

            }
            .toAttrs()
            {
                onInput {
                    onChange(it.value)
                }
            }
    )
}

@Composable
private fun HerdField(hid: Long, addNewHerd: () -> Unit, onChange: (String) -> Unit) {
    val sumVm = AppData.summaryViewModel
    var selectColor: CSSColorValue by remember { mutableStateOf(Colors.Transparent) }
    LaunchedEffect(true) {
        delay(100)
        val select = document.getElementById("herd-select$hid") as HTMLSelectElement
        select.onchange = {
            if (select.value == "-2") {
                select.value = "-1"
                addNewHerd()
            } else {
                if (select.value == "-1") {
                    selectColor = Colors.Transparent
                } else {
                    sumVm.herdList?.find { it.id == select.value.toLong() }?.let {
                        if (it.color > 0){
                            selectColor = AppColors.herdColorList[it.color - 1]
                        }

                    }
                }
                onChange(select.value)
            }
        }
    }
    Row(
        modifier = CowInputStyle.toModifier()
            .maxHeight(35.px)
            .maxWidth(170.px),
        verticalAlignment = Alignment.CenterVertically
    ) {
        CircleBox(
            modifier = Modifier
                .size(18.px)
                .backgroundColor(selectColor)
        ) {}
        Select(
            attrs = Modifier
                .id("herd-select$hid")
                .margin(left = 2.px)
                .padding(2.px)
                .border(0.px)
                .color(AppColors.TextColor)
                .fontSize(14.px)
                .toAttrs()


        ) {
            Option(
                "-1", attrs = Modifier
                    .toAttrs()
            ) {
                Text("shared_herdselect".tr())
            }

            sumVm.herdList?.filter { it.color > 0 }?.forEach { herd ->
                Option(
                    herd.id.toString(), attrs = Modifier
                        .backgroundColor(AppColors.herdColorList[herd.color - 1])
                        .toAttrs()
                ) {
                    Text(herd.name)
                }
            }

            Option(
                "-2", attrs = Modifier
                    .toAttrs()
            ) {
                Text("shared_addgrup".tr())
            }
        }
    }

}

