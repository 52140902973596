package com.kelimesoft.cowmaster.models

import com.kelimesoft.cowmaster.utils.*
import com.kelimesoft.cowmaster.utils.sameDay
import kotlinx.serialization.Serializable
import kotlinx.serialization.Transient
import org.jetbrains.compose.web.css.CSSColorValue
import kotlin.js.Date

/*
type AppCowInfo struct {
    Detay  *AppCowShort  `json:"detay"`
    Images []CowImage    `json:"images"`
    Events []NewCowEvent `json:"events"`
    Rasyon *CowRasyon    `json:"rasyon"`
    Notifs []NewCowNotif     `json:"notifs"`
    Calves *[]ListCalf       `json:"calves"`
    Milk   *[]CowSagmalDonem `json:"milk"`
}*/

@Serializable
data class CowDetails(
    val detay: CowDetay?,
    val images: List<CowImage>?,
    val events: List<CowEvent>?,
    val rasyon: CowRasyon?,
    val notifs: List<CowNotif>?,
    val calves: List<CowCalf>?,
    val milk: List<CowMilk>?,
)

@Serializable
data class CowImage(
    val hid: Long,
    val img: String,
    var fav: Int,
    val date: String,
    val exist: Boolean
)

@Serializable
data class CowRasyon(
    val id: Long,
    val name: String,
    val cost: Double,
    val type: String
)

@Serializable
data class ShorCowDetails(
    val events: List<CowEvent>?,
    val icons: List<CowStateInfo>?,
    val notifs: List<CowNotif>?,
    val milk: List<CowMilk>?,
)

/*
type AppCowShort struct {
    Hid    int    `json:"hid"`
    Kupe   string `json:"kupe"`
    Name   string `json:"name"`
    Breed  string `json:"breed"`
    Gender int    `json:"gender"`
    Birth  string `json:"birth"`
    Notes  string `json:"notes"`
    Dam    string `json:"dam"`
    Sire   string `json:"sire"`
}*/


@Serializable
data class CowDetay(
    val hid: Long,
    var kupe: String,
    var name: String,
    var breed: String,
    val gender: Int,
    val birth: String,
    var notes: String,
    var dam: String,
    var sire: String,
)

@Serializable
data class CowCalf(
    val hid: Long,
    val kp: String,
    val ism: String,
    val snf: Int,
    val dtrh: String,
    val ctrh: String,
    val csebep: String
){
    fun getSnfIcon(): String {
        return when (snf) {
            1 -> Icons.buz
            2 -> Icons.dana
            3 -> Icons.tosun
            4 -> Icons.duve
            else -> Icons.inek
        }
    }
}


@Serializable
data class CowEvent(
    val id: Long,
    val hid: Long,
    val cat: String,
    val date: String,
    val deger: Int,
    val info: String,
    val not: String,
    @Transient var icon: String = "",
    @Transient var title: String = ""
){
    init {
        setTitleIcon()
    }

    private fun setTitleIcon() {
        when (cat) {
            "TY" -> {
                icon = Icons.tohumlu
                title = "durumTY".tr()
            }

            "DY" -> {
                icon = Icons.dogumyapti
                title = "durumDY".tr()
            }

            "HG" -> {
                icon = Icons.hasta
                title = "durumHG".tr()
            }

            "AY" -> {
                icon = Icons.asi
                title = "durumAY".tr()
            }

            "AB" -> {
                icon = Icons.dusuk
                title = "durumAB".tr()
            }

            "KG" -> {
                icon = Icons.kizginlik
                title = "durumKG".tr()
            }

            "KA" -> {
                icon = Icons.kuru
                title = "durumKA".tr()
            }

            "GK" -> if (deger == 1) {
                title = "durumGKp".tr()
                icon = Icons.gebetrue
            } else if (deger == 0) {
                title = "durumGKn".tr()
                icon = Icons.gebefalse
            } else {
                title = "durumGK".tr()
                icon = Icons.gebekontrol
            }

            "KO" -> {
                title = "durumKOr".tr().Sprintf(deger)
                icon = Icons.tarti
            }
            else -> icon = Icons.diger
        }
    }
}



@Serializable
data class CowNotif(
    val id: Long,
    val eid: Long = 0,
    val ecat: String = "",
    val edate: String = "",
    val title: String,
    val text: String,
    val start: String,
    val end: String,
    var done: Int = 0
){
    fun notifColor(): CSSColorValue {
        if (done == 1){
            return AppColors.Primary
        }
        val today = Date().startOfDay()
        val localDate = Date(Date(start).toLocaleDateString())
        if (localDate.sameDay(today)){
            return AppColors.NotifNow
        }else if (localDate.startOfDay().isBefore(today)){
            return AppColors.NotifPassed
        }
        return AppColors.NotifLater
    }
}

@Serializable
data class CowStateInfo(
    val res: Int,
    val title: String,
    val text: String?
){
    val icon: String
        get() = when (res) {
            1 -> Icons.dogumyapti
            2 -> Icons.dusuk
            3 -> Icons.repeatbreeding
            4 -> Icons.hasta
            5 -> Icons.kizginlik
            6 -> Icons.tohumlu
            7 -> Icons.gebe
            8 -> Icons.gebesoru
            9 -> Icons.kuru
            10 -> Icons.asi
            11 -> Icons.sutkes
            12 -> Icons.tarti
            13 -> Icons.sagmal
            14 -> Icons.biberon
            else -> ""
        }


}

@Serializable
data class CowMilk(
    val hid: Long,
    val bastrh: String,
    val sontrh: String,
    val sutmik: Double,
    val avg: Double,
    val dim: Int
)