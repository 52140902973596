package com.kelimesoft.cowmaster.pages.summary

import StaggeredVerticalGrid2
import androidx.compose.runtime.Composable
import com.kelimesoft.cowmaster.components.ClickableSummaryCard

import com.kelimesoft.cowmaster.components.InfoText
import com.kelimesoft.cowmaster.components.SummaryCard
import com.kelimesoft.cowmaster.models.CowUser
import com.kelimesoft.cowmaster.models.Screens

import com.kelimesoft.cowmaster.utils.Sprintf
import com.kelimesoft.cowmaster.utils.tr
import com.kelimesoft.cowmaster.viewmodals.AppData
import com.kelimesoft.cowmaster.viewmodals.SummaryViewModel
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.overflow
import com.varabyte.kobweb.compose.ui.modifiers.overflowX
import com.varabyte.kobweb.compose.ui.modifiers.overflowY
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import kotlin.js.Date

@Composable
fun SummaryContent(sumVM: SummaryViewModel) {
    val breakpoint = rememberBreakpoint()

    val cowUser: CowUser? = AppData.cowUser
    Column(modifier = Modifier
        .overflow(Overflow.Hidden)
        .fillMaxSize(),
        verticalArrangement = Arrangement.Top,
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        SummaryBar(sumVM.cattleNumbers.total, sumVM.herdList?.size ?: 0, sumVM.dailyNotif)
        Column(modifier = Modifier
            .overflow(overflowY = Overflow.Auto, overflowX = Overflow.Hidden)
            .fillMaxSize(),
            verticalArrangement = Arrangement.Top,
            horizontalAlignment = Alignment.CenterHorizontally
        ){
            StaggeredVerticalGrid2(gap = 10){
                SummaryCard(title = "sum_drmtitle".tr()){
                    StateSummary(sumVM)
                }
                SummaryCard(title = "sum_haytitle".tr()){
                    NumberSummary(sumVM)
                }

                SummaryCard(title = "sum_disdeltitle".tr()){
                    DisposedDismisSum(sumVM){
                        sumVM.showDeletedList = true
                    }
                }

                ClickableSummaryCard(path = Screens.Milk.path, title = "sum_milktitle".tr()){
                    MilkSummary(sumVM.milkSum)
                    //Text("asdasd")
                }
                if (cowUser?.canSeeFin == true){
                    ClickableSummaryCard(path = Screens.Finance.path, title = "sum_fintitle".tr()){
                        FinanceSummary(sumVM.financeSum)
                    }
                }

                ClickableSummaryCard(path = Screens.Notifs.path, title = "sum_notiftitle".tr()){
                    NotifSummary(sumVM.notifList)
                    if (sumVM.notifList.isEmpty()){
                        InfoText("Dönem içinde bildirim bulunamadı")
                    }
                }

                ClickableSummaryCard(path = Screens.Events.path, title = "sum_evttitle".tr()){
                    LatestEvents(sumVM.eventList)
                    if (sumVM.eventList.isEmpty()){
                        InfoText("Son 15 gün içinde durum kaydı yapılmadı")
                    }

                }
                /*
                ClickableSummaryCard(path = Screens.Dismiss.path,
                    title = "sum_distitle".tr().Sprintf(Date().getFullYear())){
                    DismissSummary(sumVM.dismissList)
                    if (sumVM.dismissList.isEmpty()){
                        InfoText("${Date().getFullYear()} yılında işletmeden hayvan çıkarılmadı.")
                    }
                    //Text("asdasd")
                }
                */
            }

        }


    }
}