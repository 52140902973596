package com.kelimesoft.cowmaster.components

import com.varabyte.kobweb.navigation.*



import androidx.compose.runtime.*
import com.varabyte.kobweb.compose.dom.ElementRefScope
import com.varabyte.kobweb.compose.dom.registerRefScope
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.core.init.KobwebConfig
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.navigation.LinkStyle
import com.varabyte.kobweb.silk.components.style.ComponentVariant
import com.varabyte.kobweb.silk.components.style.toModifier
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.ContentBuilder
import org.w3c.dom.HTMLAnchorElement
import org.w3c.dom.HTMLElement

@Composable
fun ReplaceLink(
    path: String,
    modifier: Modifier = Modifier,
    replaceLink: Boolean = false,
    variant: ComponentVariant? = null,
    openInternalLinksStrategy: OpenLinkStrategy? = null,
    openExternalLinksStrategy: OpenLinkStrategy? = null,
    autoPrefix: Boolean = true,
    ref: ElementRefScope<HTMLElement>? = null,
    content: @Composable () -> Unit
) {
    ReplaceAnchor(
        href = path,
        attrs = LinkStyle.toModifier(variant).then(modifier).toAttrs(),
        replaceLink,
        openInternalLinksStrategy,
        openExternalLinksStrategy,
        autoPrefix
    ) {
        registerRefScope(ref)
        content()
    }
}

@Composable
fun ReplaceAnchor(
    href: String,
    attrs: AttrBuilderContext<HTMLAnchorElement>? = null,
    replaceLink: Boolean = false,
    openInternalLinksStrategy: OpenLinkStrategy? = null,
    openExternalLinksStrategy: OpenLinkStrategy? = null,
    autoPrefix: Boolean = true,
    content: ContentBuilder<HTMLAnchorElement>? = null
) {
    @Suppress("NAME_SHADOWING") // Intentional shadowing for in-place transformation
    val href = RoutePrefix.prependIf(autoPrefix, href)

    val ctx = rememberPageContext()
    A(
        href,
        attrs = {
            if (attrs != null) {
                attrs()
            }
            @Suppress("NAME_SHADOWING") // Intentional shadowing - nullable to non-null
            onClick { evt ->
                val openInternalLinksStrategy = openInternalLinksStrategy
                    ?: evt.toOpenLinkStrategy(KobwebConfig.Instance.openLinkStrategies.internal)
                val openExternalLinksStrategy = openExternalLinksStrategy
                    ?: evt.toOpenLinkStrategy(KobwebConfig.Instance.openLinkStrategies.external)
                ctx.router.navigateTo(
                    href,
                    updateHistoryMode = if (replaceLink) UpdateHistoryMode.REPLACE else UpdateHistoryMode.PUSH,
                    openInternalLinksStrategy = openInternalLinksStrategy,
                    openExternalLinksStrategy = openExternalLinksStrategy
                )
                evt.preventDefault()
                evt.stopPropagation()
            }
        },
        content
    )
}
