package com.kelimesoft.cowmaster.models

object Routes {
    const val Summary = "/ozet"
    const val CowList = "/liste"
    const val Milk = "/sut"
    const val Notifs = "/bildirim"
    const val Finance = "/gelir-gider"
    const val Profile = "/profil"
    const val Login = "/login"

    //const val WebRoot = "http://localhost:9010/" //local testlerde bu
    const val WebRoot = "https://cow-master.app/" //local testlerde bu
    const val ImgRoot = "https://cow-master.app/cowimages/" //local testlerde bu
    //const val WebRoot = "/" //web sitesinde bu
    const val ApiRoot = WebRoot + "web1/"
    //const val AppRoot = "http://localhost:9010/app1/" //local testlerde bu
    const val AppRoot = "https://cow-master.app/app1/" //local testlerde bu
}
