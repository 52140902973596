package com.kelimesoft.cowmaster.pages.notif

import androidx.compose.runtime.*
import com.kelimesoft.cowmaster.components.DialogTitle
import com.kelimesoft.cowmaster.models.AppColors
import com.kelimesoft.cowmaster.models.PersonNotif
import com.kelimesoft.cowmaster.models.opacity
import com.kelimesoft.cowmaster.styles.CowInputStyle
import com.kelimesoft.cowmaster.styles.MainButtonStyle
import com.kelimesoft.cowmaster.styles.TextButtonStyle
import com.kelimesoft.cowmaster.utils.Funcs
import com.kelimesoft.cowmaster.utils.dateToDbStr
import com.kelimesoft.cowmaster.utils.tr
import com.kelimesoft.cowmaster.viewmodals.AppData
import com.kelimesoft.cowmaster.viewmodals.NotifListVm
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.attrsModifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.style.toModifier
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.attributes.forId
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.*
import kotlin.js.Date

@Composable
fun AddNotif(scope: CoroutineScope, notifListVm: NotifListVm, onClose: () -> Unit) {
    var errorText by remember {mutableStateOf("")}
    var notifDate by remember { mutableStateOf(Date().dateToDbStr()) }
    var notifTitle by remember {mutableStateOf("")}
    var notifText by remember {mutableStateOf("")}
    var forAll by remember {mutableStateOf(false)}

    fun saveNotif(){
        if (!Funcs.checkForm("form-notif")){
            return
        }
        val cowNotif = PersonNotif(
            hid = 0,
            date = Date(notifDate).dateToDbStr(),
            title = notifTitle,
            text = notifText,
            user = if (!forAll) AppData.cowUser?.email ?: "all" else "all"
        )
        scope.launch {
            notifListVm.addNewNotif(cowNotif)
            onClose()
        }
    }

    Column(
        modifier = Modifier
            .fillMaxWidth()
            .maxWidth(460.px)
            .boxShadow(offsetY = 2.px, offsetX = 1.px, color = AppColors.Background)
            .padding(4.px)
            .borderRadius(r = 10.px)
            .backgroundColor(Colors.White)
    ) {

        DialogTitle("addnotif_add".tr(), onClose = onClose)

        Column(
            Modifier
                .fillMaxWidth()
                .padding(topBottom = 6.px, leftRight = 8.px)
        ) {

            Form(
                attrs = Modifier
                    .id("form-notif")
                    .fillMaxWidth()
                    .toAttrs()
            ) {
                Column(modifier = Modifier.fillMaxWidth(),
                    horizontalAlignment = Alignment.Start) {
                    Span(attrs = Modifier
                        .fontSize(12.px)
                        .margin(top = 6.px)
                        .padding(1.px)
                        .color(AppColors.TextColor.copyf(alpha = 0.6f))
                        .toAttrs()) {
                        Text("addnotif_date".tr())
                    }

                    Input(
                        type = InputType.Date,
                        attrs = CowInputStyle.toModifier()
                            .id("notif-date")
                            .padding(7.px)
                            .attrsModifier {
                                attr("required", "true")
                                attr("min", Date().dateToDbStr())
                            }
                            .margin(bottom = 10.px)
                            .toAttrs()
                            {
                                onInput {
                                    notifDate = it.value
                                }
                            }
                    )
                }


                Input(
                    type = InputType.Text,
                    attrs = CowInputStyle.toModifier()
                        .attrsModifier {
                            attr("name", "title")
                            attr("required", "true")
                            attr("placeholder", "addnotif_baslik".tr())
                        }
                        .margin(bottom = 10.px)
                        .toAttrs()
                        {
                            onInput {
                                notifTitle = it.value
                            }
                        }
                )

                Input(
                    type = InputType.Text,
                    attrs = CowInputStyle.toModifier()
                        .attrsModifier {
                            attr("name", "text")
                            attr("required", "true")
                            attr("placeholder", "addnotif_not".tr())
                        }
                        .margin(bottom = 10.px)
                        .toAttrs()
                        {
                            onInput {
                                notifText = it.value
                            }
                        }
                )

                if (AppData.cowUser?.isAdmin == true){
                    Row(verticalAlignment = Alignment.CenterVertically) {
                        CheckboxInput(checked = forAll, attrs = Modifier
                            .id("check-notif")
                            .size(18.px)
                            .toAttrs {
                            onChange {
                                forAll = it.value
                            }
                        })
                        Label(attrs = Modifier
                            .fontSize(13.px)
                            .color(AppColors.DarkRed.opacity(0.8f))
                            .toAttrs{
                                forId("check-notif")
                            }) {
                            Text("addnotif_allusers".tr())
                        }
                    }
                }

            }
            Row(
                modifier = Modifier.fillMaxWidth()
                    .margin(top = 20.px),
                verticalAlignment = Alignment.CenterVertically
            ) {
                Button(
                    attrs = TextButtonStyle.toModifier()
                        .fontSize(16.px)
                        .color(AppColors.DarkRed)
                        .onClick {
                            it.preventDefault()
                            onClose()
                        }
                        .toAttrs()

                ) {
                    Text("shared_iptal".tr())
                }


                Spacer()
                Button(
                    attrs = MainButtonStyle.toModifier()
                        //.backgroundColor(AppColors.Red)
                        .onClick {
                            it.preventDefault()
                            saveNotif()
                        }
                        .toAttrs()

                ) {
                    Text("addnotif_add".tr())
                }
            }
        }
    }

}

