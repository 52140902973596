package com.kelimesoft.cowmaster.models


import org.jetbrains.compose.web.css.px

object Images {
    const val appIcon120 = "img/cow-master-120px.png"
    const val noImage = "img/img_cow_noimage.png"
}


object Values {
    val barHeight = 36.px
    val innerBarHeight = 32.px
    val bottomBarHeight = 64.px
    const val FONT_FAMILY = "Sans-serif"
    const val imgPath1 = "./"
    const val imgPath2 = "../"

}

object Icons {
    const val dairy = "img/ic_dairy.png"
    const val milk = "img/ic_gugum_w.png"
    const val cows = "img/ic_hayvan_sayi.png"
    const val finance = "img/ic_finance.png"

    const val inekw = "img/ic_inek_w.png"
    const val duvew = "img/ic_duve_w.png"
    const val tosunw = "img/ic_tosun_w.png"
    const val danaw = "img/ic_dana_w.png"
    const val buzw = "img/ic_buzagi.png"

    const val inek = "img/ic_inek.png"
    const val duve = "img/ic_duve.png"
    const val tosun = "img/ic_tosun.png"
    const val dana = "img/ic_dana.png"
    const val buz = "img/ic_buzagi.png"

    const val hasta = "img/drm_hasta.png"
    const val gebe = "img/drm_gebe.png"
    const val tohumlu = "img/drm_tohumlama.png"
    const val kuru = "img/drm_kuru_inek.png"
    const val sagmal = "img/drm_sagmal_inek.png"
    const val taze = "img/drm_dogum.png"
    const val open = "img/drm_open.png"
    const val gebesoru = "img/drm_gebe_soru.png"
    const val gebetrue = "img/drm_gebe_true.png"
    const val gebefalse = "img/drm_gebe_false.png"
    const val gebekontrol = "img/drm_gebe_kontrol.png"
    const val dogumyapti = "img/drm_dogum.png"
    const val dusuk = "img/drm_dusuk.png"
    const val repeatbreeding = "img/drm_repeat_breeding.png"
    const val kizginlik = "img/drm_kizginlik.png"
    const val asi = "img/drm_asi.png"
    const val sutkes = "img/drm_sut_kes.png"
    const val tarti = "img/drm_kilo_tarti.png"
    const val diger = "img/drm_diger.png"
    const val none = "img/drm_select.png"

    const val biberon = "img/ic_biberon.png"
    const val gugum = "img/ic_gugum.png"

    const val dismissCow = "img/ic_removed.png"

    const val finReceipt = "img/ic_receipt.png"

}