package com.kelimesoft.cowmaster.pages.finance

import androidx.compose.runtime.*
import com.kelimesoft.cowmaster.components.ErrorText
import com.kelimesoft.cowmaster.components.FaRefresh
import com.kelimesoft.cowmaster.models.AppColors
import com.kelimesoft.cowmaster.models.Values
import com.kelimesoft.cowmaster.styles.CowInputStyle
import com.kelimesoft.cowmaster.styles.DateSelectStyle
import com.kelimesoft.cowmaster.utils.*
import com.kelimesoft.cowmaster.viewmodals.FinanceVM
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.functions.max
import com.varabyte.kobweb.compose.css.functions.opacity
import com.varabyte.kobweb.compose.css.height
import com.varabyte.kobweb.compose.css.width
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.*
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.icons.fa.FaChevronLeft
import com.varabyte.kobweb.silk.components.icons.fa.FaChevronRight
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import kotlinx.browser.document
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.attributes.max
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.display
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.*
import org.w3c.dom.HTMLInputElement
import kotlin.js.Date


@Composable
fun FinBar(financeVM: FinanceVM, scope: CoroutineScope) {
    val breakpoint = rememberBreakpoint()

    var startDate by remember { mutableStateOf(financeVM.startDate) }
    var endDate by remember { mutableStateOf(financeVM.endDate) }
    var barError by remember { mutableStateOf("") }


    fun isValidDate(date: String): Boolean {
        val regex = Regex("""\d{4}-\d{2}-\d{2}""")
        return regex.matches(date)
    }

    Row(
        modifier = Modifier
            .fillMaxWidth()
            .padding(6.px)
            .boxShadow(
                offsetX = 1.px,
                offsetY = 1.px,
                color = Colors.Black.copyf(alpha = 0.1f)
            )
            .minWidth(240.px)
            .backgroundColor(AppColors.BarColor)
            .height(Values.barHeight),
        verticalAlignment = Alignment.CenterVertically
    ) {
        ChevronLeft {
            startDate = startDate.previousMonth().firstDayOfMonth()
            endDate = startDate.lastDayOfMonth()
            financeVM.startDate = startDate
            financeVM.endDate = endDate
            scope.launch {
                financeVM.getFinanceList(true)
            }
        }
        DateInput(
            value = startDate.dateToDbStr(),
            attrs = DateSelectStyle.toModifier()
                .toAttrs {
                    id("startdate-picker")
                    onChange {
                        val newDate = it.value
                        if (isValidDate(newDate)) {
                            barError = ""
                            startDate = Date(newDate)
                        }
                    }

                }
        )

        Span(
            attrs = Modifier
                .color(AppColors.TextColor)
                .margin(leftRight = 4.px)
                .padding(leftRight = 4.px)
                .fontSize(12.px)
                .toAttrs()
        ) {
            Text(" - ")
        }
        DateInput(
            value = endDate.dateToDbStr(),
            attrs = DateSelectStyle.toModifier()
                .toAttrs {
                    id("enddate-picker")
                    onChange {
                        val newDate = it.value
                        if (isValidDate(newDate)) {
                            barError = ""
                            endDate = Date(newDate)
                        }
                    }

                }
        )
        ChevronRight {
            startDate = startDate.nextMonth().firstDayOfMonth()
            endDate = startDate.lastDayOfMonth()
            financeVM.startDate = startDate
            financeVM.endDate = endDate
            scope.launch {
                financeVM.getFinanceList(true)
            }
        }

        if (financeVM.startDate.dateToDbStr() != startDate.dateToDbStr()
            || financeVM.endDate.dateToDbStr() != endDate.dateToDbStr() ) {
            Button(attrs = DateSelectStyle.toModifier()
                .fontSize(13.px)
                .color(AppColors.TextColor)
                .margin(leftRight = 4.px, topBottom = 2.px)
                .toAttrs {
                    onClick {
                        if (startDate == financeVM.startDate
                            && endDate == financeVM.endDate
                        ) {
                            return@onClick
                        }
                        if (startDate.isBefore(endDate)) {
                            financeVM.startDate = startDate
                            financeVM.endDate = endDate
                            scope.launch {
                                financeVM.getFinanceList(true)
                            }

                        } else {
                            barError = "finbar_dateerr".tr()
                        }

                    }
                }) {
                Text("Yenile")
                FaRefresh(
                    modifier = Modifier
                        .margin(left = 4.px)
                        .color(AppColors.Primary),

                    size = IconSize.SM
                )
            }
        }

        if (barError.isNotEmpty()) {
            ErrorText(barError)
        }
        Spacer()

        SpanText(
            "finbar_total".tr(),
            modifier = Modifier.fontSize(16.px).fontWeight(FontWeight.Medium).color(AppColors.TextColor)
        )
        SpanText(financeVM.gelirToplam.round(2), modifier = Modifier.color(AppColors.Primary))
        SpanText(
            "  -  ",
            modifier = Modifier.fontSize(16.px).fontWeight(FontWeight.SemiBold).color(AppColors.TextColor)
        )
        SpanText(financeVM.giderToplam.round(2), modifier = Modifier.color(AppColors.DarkRed))
        SpanText(
            "  =  ",
            modifier = Modifier.fontSize(16.px).fontWeight(FontWeight.SemiBold).color(AppColors.TextColor)
        )
        SpanText(
            (financeVM.gelirToplam - financeVM.giderToplam).round(2),
            modifier = Modifier.color(if (financeVM.giderToplam > financeVM.gelirToplam) AppColors.DarkRed else AppColors.PrimaryDark)
        )

    }

}


@Composable
fun ChevronRight(onClick: () ->Unit){
    Button(attrs = DateSelectStyle.toModifier()
        .margin(leftRight = 4.px, topBottom = 2.px)
        .toAttrs {
            onClick {
                onClick()
            }
        }) {

        FaChevronRight(
            modifier = Modifier
                .margin(left = 4.px)
                .color(AppColors.Primary),
            size = IconSize.LG
        )
    }
}

@Composable
fun ChevronLeft(onClick: () ->Unit){
    Button(attrs = DateSelectStyle.toModifier()
        .margin(leftRight = 4.px, topBottom = 2.px)
        .toAttrs {
            onClick {
                onClick()
            }
        }) {

        FaChevronLeft(
            modifier = Modifier
                .margin(left = 4.px)
                .color(AppColors.Primary),
            size = IconSize.LG
        )
    }
}
