package com.kelimesoft.cowmaster.viewmodals

import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.setValue
import com.kelimesoft.cowmaster.models.Screens
import com.kelimesoft.cowmaster.network.RestApi
import com.kelimesoft.cowmaster.network.UserResponse
import com.kelimesoft.cowmaster.utils.AppStorage
import com.kelimesoft.cowmaster.viewmodals.AppData
import com.kelimesoft.cowmaster.viewmodals.CowListViewModel
import com.kelimesoft.cowmaster.viewmodals.NotifListVm
import com.kelimesoft.cowmaster.viewmodals.SummaryViewModel
import kotlinx.serialization.json.Json


class AppViewModel {
    var activeScreens: Screens by mutableStateOf(Screens.Splash)
    var showProgress by mutableStateOf(false)

    init {
        setSessionId()
    }

    suspend fun getUser() {
        if (AppData.sessionId.isEmpty()) {
            activeScreens = Screens.Login
        }else {
            if (AppData.cowUser == null){
                val result = getUserInfo()
                if(!result){
                    activeScreens = Screens.Login
                }else if (activeScreens == Screens.Splash){
                    activeScreens = Screens.Summary
                }else{
                    activeScreens = activeScreens
                }
            }else if (activeScreens == Screens.Splash){
                activeScreens = Screens.Summary
            }else{
                activeScreens = activeScreens
            }
        }
    }

    suspend fun getUserInfo(): Boolean {
        RestApi.getUserProfile()?.let { res ->
            Json.decodeFromString<UserResponse>(res).let { userResponse ->
                if(userResponse.data != null){
                    AppData.cowUser = userResponse.data
                    AppData.sessionId = AppData.cowUser!!.sessionId
                    AppStorage.setString(AppStorage.sessionId, AppData.sessionId)
                    return true
                }
            }
        }
        return false
    }


    private fun setSessionId(){
        if (AppData.sessionId.isEmpty()){
            val sessionId = AppStorage.getString(AppStorage.sessionId)
            if (sessionId.isNotEmpty()){
                AppData.sessionId = sessionId
            }
        }
    }

    suspend fun userLogout() {
        RestApi.logOut()?.let {
            AppData.cowUser = null
            AppData.sessionId = ""
            AppStorage.remove(AppStorage.sessionId)
            AppData.summaryViewModel = SummaryViewModel()
            AppData.cowListVM = CowListViewModel()
            AppData.notifListVm = NotifListVm()
            activeScreens = Screens.Login
        }
    }

    fun setPage(error: String){
        when(error){
            "login_required" -> activeScreens = Screens.Login
            "not_premium" -> activeScreens = Screens.NoPremium
        }
    }

}


