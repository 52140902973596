package com.kelimesoft.cowmaster.styles

import com.kelimesoft.cowmaster.models.AppColors
import com.kelimesoft.cowmaster.models.Values
import com.varabyte.kobweb.compose.css.*
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.hover
import org.jetbrains.compose.web.css.deg
import org.jetbrains.compose.web.css.ms
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px

val ErrorTextStyle by ComponentStyle {
    base {
        Modifier
            .fillMaxWidth()
            .fontSize(14.px)
            .textAlign(TextAlign.Center)
            .color(AppColors.NumRed)
            .transition(
                CSSTransition(property = "display", duration = 200.ms),
            )
    }
}

val SuccesTextStyle by ComponentStyle {
    base {
        Modifier
            .fillMaxWidth()
            .fontSize(14.px)
            .textAlign(TextAlign.Center)
            .color(AppColors.PrimaryDark)
            .transition(
                CSSTransition(property = "display", duration = 200.ms),
            )
    }
}

val DetailInfoTitleStyle by ComponentStyle {
    base {
        Modifier
            .fontSize(14.px)
            .color(AppColors.TextColor.copyf(alpha = 0.8f))
    }
}

val DetailInfoDataStyle by ComponentStyle {
    base {
        Modifier
            .fontSize(15.px)
            .fontWeight(FontWeight.Medium)
            .color(AppColors.TextColor.copyf(alpha = 0.9f))
    }
}


val CowMilkTitleStyle by ComponentStyle {
    base {
        Modifier
            .fontSize(11.px)
            .color(AppColors.TextColor.copyf(alpha = 0.8f))
    }
}

val CaptchaStyle by ComponentStyle {
    base {
        Modifier
            .padding(leftRight = 6.px, topBottom = 3.px)
            .fontSize(FontSize.Large)
            .color(AppColors.GrayDark)
            .textDecorationLine(TextDecorationLine.LineThrough)
            .userSelect(UserSelect.None)
            .fontStyle(FontStyle.Italic)
            .backgroundColor(Colors.Gray.copyf(alpha = 0.2f))
            .boxShadow(1.px, 1.px, color = Colors.Gray.copyf(alpha = 0.1f))
            .borderRadius(r = 2.px)
            .rotate(((-5..5).random()).deg)
    }
}