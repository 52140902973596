package com.kelimesoft.cowmaster.styles

import com.kelimesoft.cowmaster.models.AppColors
import com.varabyte.kobweb.compose.css.CSSTransition
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.hover
import org.jetbrains.compose.web.css.ms
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px

val StaggeredItemStyle by ComponentStyle {
    base {
        //bu özellik staggered layout için
        Modifier
            .boxShadow(2.px, 2.px, 3.px, color = Colors.Black.copyf(alpha = 0.3f))
            .styleModifier {
                property("break-inside", "avoid")
            }

    }
}
val SummaryCardstyle by ComponentStyle {
    base {
        //bu özellik staggered layout için
        Modifier
            .fillMaxWidth()
            .margin(bottom = 6.px)
            .backgroundColor(Colors.White)
            .borderRadius(r = 10.px)
    }
}

val DetayCardstyle by ComponentStyle {
    base {
        //bu özellik staggered layout için
        Modifier
            .fillMaxWidth()
            .maxWidth(660.px)
            .minWidth(250.px)
            .margin(bottom = 4.px)
            .padding(4.px)
            .backgroundColor(Colors.White.copyf(alpha = 0.9f))
            .borderRadius(r = 8.px)

    }
}

val ClickableSummaryCardstyle by ComponentStyle {
    base {
        //bu özellik staggered layout için
        Modifier
            .fillMaxWidth()
            .margin(bottom = 6.px)
            .backgroundColor(Colors.White)
            .borderRadius(r = 10.px)
            .transition(
                CSSTransition("box-shadow", duration = 200.ms),
                CSSTransition("background", duration = 200.ms)
            )

    }

    hover {
        Modifier
            .backgroundColor(AppColors.DropMenu)
            .boxShadow(2.px, 2.px, 3.px, 2.px, color = Colors.Black.copyf(alpha = 0.4f))
            .cursor(Cursor.Pointer)
    }
}