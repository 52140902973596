package com.kelimesoft.cowmaster.pages.notif

import androidx.compose.runtime.*
import com.kelimesoft.cowmaster.components.RowButton
import com.kelimesoft.cowmaster.components.VDivider
import com.kelimesoft.cowmaster.models.AppColors
import com.kelimesoft.cowmaster.models.ListNotif
import com.kelimesoft.cowmaster.models.opacity
import com.kelimesoft.cowmaster.utils.*
import com.kelimesoft.cowmaster.viewmodals.NotifListVm
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.icons.fa.FaCheck
import com.varabyte.kobweb.silk.components.icons.fa.FaTrash
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import kotlin.js.Date

@Composable
fun NotifList(
    modifier: Modifier = Modifier,
    breakpoint: Breakpoint,
    search: MutableState<String>,
    showTmm: MutableState<Boolean>,
    notifGrup: String?,
    notifListVm: NotifListVm,
    onAddNotif: () -> Unit
) {
    val scope = rememberCoroutineScope()
    val filteredList: List<ListNotif> =
        notifListVm.notifList.filter { notif ->
            (notif.done < if (showTmm.value) 2 else 1) &&
                    (if (notifGrup == null) notif.title.isNotEmpty() else notif.title == notifGrup)  &&
            (notif.name.lowercase().contains(search.value.lowercase())
                    || notif.text.lowercase().contains(search.value.lowercase())
                    || notif.kupe.lowercase().contains(search.value.lowercase())
                    || notif.title.lowercase().contains(search.value.lowercase()))
        }

    Column(
        modifier = modifier
            //.fillMaxWidth(if (breakpoint > Breakpoint.SM) 50.percent else 98.percent)
            .minWidth(if (breakpoint > Breakpoint.SM) 320.px else 260.px)
            //.maxWidth(if (breakpoint > Breakpoint.SM) 560.px else 1000.px)
            .borderRadius(topRight = 8.px, topLeft = 8.px)
            .fillMaxHeight()
            .overflow(Overflow.Hidden)
            .backgroundColor(Colors.White)
    ) {
        NotifBar(showTmm, search, breakpoint) {
            onAddNotif()
        }

        //CowSearch(search)
        Column(
            modifier = Modifier
                .fillMaxWidth()
                .padding(leftRight = 6.px)
                .overflow { y(Overflow.Auto) }
                .overflow { x(Overflow.Hidden) }

        ) {
            filteredList.forEachIndexed { index, notif ->
                //(n.bastrh <= '%s' AND n.bittrh >= '%s'), son, ilk
                val selected = Date(notif.start).isBefore(notifListVm.selectEnd.endOfDay())
                        && Date(notif.end).isAfter(notifListVm.selectStart.startOfDay())
                if (selected) {
                    NotifRowList(notif, onComplete = {
                        scope.launch {
                            notifListVm.toggleNotif(notif.id, if (notif.done == 1) 0 else 1)
                        }
                    }, onDelete = {
                        scope.launch {
                            notifListVm.deleteNotif(notif.id)
                        }
                    })
                }

            }
        }
    }
}


@Composable
fun NotifRowList(notif: ListNotif, onComplete: () -> Unit, onDelete: () -> Unit) {
    var showButtons by remember { mutableStateOf(false) }
    Column(
        modifier = Modifier.fillMaxWidth(),
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        Box(
            modifier = Modifier.fillMaxWidth()
                .padding(2.px),
            contentAlignment = Alignment.TopEnd
        ) {
            Row(
                modifier = Modifier.fillMaxWidth()
                    .padding(leftRight = 6.px)
                    .fillMaxWidth()
                    .onMouseOver {
                        showButtons = true
                    }
                    .onMouseLeave {
                        showButtons = false
                    },
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.Start
            ) {
                if (showButtons) {
                    Row(
                        horizontalArrangement = Arrangement.Start
                    ) {
                        RowButton {
                            FaCheck(
                                modifier = Modifier
                                    .color(AppColors.Primary.opacity(0.9f))
                                    .onClick {
                                        onComplete()
                                    },
                                size = IconSize.LG,

                                )
                        }
                        RowButton {
                            FaTrash(
                                modifier = Modifier.color(AppColors.DarkRed.opacity(0.9f))
                                    .onClick {
                                        onDelete()
                                    },
                                size = IconSize.SM
                            )
                        }

                    }
                } else {
                    Box(
                        modifier = Modifier
                            .size(24.px)
                            .borderRadius(r = 50.percent)
                            .backgroundColor(notif.notifColor())
                            .margin(right = 8.px)
                    )
                }
                Column(modifier = Modifier.fillMaxWidth()) {
                    P(
                        attrs = Modifier
                            .fillMaxWidth()
                            .textAlign(textAlign = TextAlign.Start)
                            .fontSize(14.px)
                            .margin(1.px)
                            .color(AppColors.TextColor)
                            .fontWeight(FontWeight.Medium)
                            .toAttrs()
                    ) {
                        Span(
                            attrs = Modifier
                                .fontWeight(FontWeight.SemiBold)
                                .toAttrs()
                        ) {
                            Text(notif.title.tr())
                        }
                        Text(", ")
                        Text(notif.kupe)


                    }
                    P(
                        attrs = Modifier
                            .fillMaxWidth()
                            .textAlign(textAlign = TextAlign.Start)
                            .fontSize(13.px)
                            .color(AppColors.TextColor)
                            .fontWeight(FontWeight.Normal)
                            .margin(1.px)
                            .opacity(90.percent)
                            .toAttrs()
                    ) {
                        Text(notif.text.tr().Sprintf(Date(notif.edate).toLocaleDateString()))

                    }
                }

            }
            P(
                attrs = Modifier
                    //.fillMaxWidth(37.percent)
                    .margin(right = 4.px)
                    .textAlign(textAlign = TextAlign.End)
                    .fontSize(14.px)
                    .color(notif.notifColor())
                    .fontWeight(FontWeight.Normal)
                    .padding(2.px)
                    .backgroundColor(AppColors.Gray.copyf(alpha = 0.2f))
                    .borderRadius(r = 6.px)
                    .zIndex(1)
                    .toAttrs()
            ) {
                Text(Date(notif.start).toLocaleDateString())
            }
        }
        VDivider()
    }

}