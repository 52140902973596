import androidx.compose.runtime.*
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import org.jetbrains.compose.web.css.AlignContent
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Div

@Composable
fun StaggeredVerticalGrid(minmax: Int, gap: Int, content: @Composable () -> Unit) {
    // Generate the CSS style for the grid container
    Box(
        modifier = Modifier
            .fillMaxWidth()
            .margin(6.px)
            //burası normal grid


            /*
            //staggered grid
            .styleModifier {
                //property("width", "1000px")
                property("margin", "0px auto")
                property("columns", "$cols")
                //property("column-gap", "10px")
            }*/,
        contentAlignment = Alignment.Center

    )
    {
        Div(
            attrs = Modifier
                .fillMaxWidth()
                .styleModifier {
            property("display", "grid")
            property("grid-template-columns", "repeat(auto-fill, minmax(${minmax}px, 1fr))")
            property("gap", "${gap}px")

            }.toAttrs()
        ){
            content()
        }

    }

    /*

    HTMLElement("div", attrs = {
        style = gridContainerStyle
    }) {
        items.forEach { item ->
            // Each grid item
            item()
        }
    }

     */
}

@Composable
fun StaggeredVerticalGrid2(col: Int = 0, gap: Int, content: @Composable () -> Unit) {
    val breakpoint = rememberBreakpoint()
    var cols by remember { mutableStateOf(1) }
    if (col == 0){
        //console.log(breakpoint.name)
        cols = if (breakpoint > Breakpoint.LG) 3
        else if(breakpoint >= Breakpoint.MD) 2
        else 1
    }else{
        cols = col
    }

    Box(
        modifier = Modifier
            .fillMaxWidth()
            .padding(6.px)
            .margin(4.px),

        contentAlignment = Alignment.Center
    )
    {
        Div(
            attrs = Modifier
                .fillMaxWidth()
                .styleModifier {
                    //property("width", "1000px")
                    property("margin", "0px auto")
                    property("columns", "$cols")
                    property("column-gap", "${gap}px")
                }.toAttrs()
        ){
            content()
        }
    }
}


/*
@Composable
fun StaggeredGridItem(content: @Composable () -> Unit) {
    // Generate the CSS style for each grid item
    val itemStyle = """
        /* Define the height or other styling attributes for the staggered effect */
    """.trimIndent()

    // Apply the CSS style to the item using raw HTML
    HTMLTag("div", attrs = {
        style = itemStyle
    }) {
        // Render the Composable content inside the grid item
        content()
    }
}*/