package com.kelimesoft.cowmaster.pages

import androidx.compose.runtime.*
import com.kelimesoft.cowmaster.components.*
import com.kelimesoft.cowmaster.models.Screens
import com.kelimesoft.cowmaster.pages.notif.NotifGroups
import com.kelimesoft.cowmaster.pages.notif.NotifList
import com.kelimesoft.cowmaster.styles.MainPageStyle
import com.kelimesoft.cowmaster.utils.AppStorage
import com.kelimesoft.cowmaster.viewmodals.AppData
import com.kelimesoft.cowmaster.pages.notif.AddNotif
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.css.PointerEvents
import com.varabyte.kobweb.compose.css.ScrollBehavior
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px

@Page("/bildirim")
@Composable
fun NotifPage() {
    AppData.appViewModel.activeScreens = Screens.Notifs
    Router()
}

/*
@Composable
fun CattleNotifs() {
    val breakpoint = rememberBreakpoint()
    Column(
        modifier = MainPageStyle.toModifier(),
        verticalArrangement = Arrangement.Top,
        horizontalAlignment = Alignment.CenterHorizontally) {
        Row(
            modifier = Modifier.fillMaxSize()
        ) {
            if (breakpoint >= Breakpoint.SM){
                LeftPane(breakpoint)
            }
            NoContentYet("Bildirimler modulü yakında etkinleştirilerilecektir, lütfen birkaç gün içinde tekrar kontrol ediniz.")

        }

        if (breakpoint < Breakpoint.SM){
            BottomNavigation(breakpoint)
        }

    }
}
*/


@Composable
fun CattleNotifs() {
    val scope = rememberCoroutineScope()
    var searchText = remember { mutableStateOf("") }
    var showAddNotif by remember { mutableStateOf(false) }

    var showTmm = remember { mutableStateOf(AppStorage.getBool(AppStorage.notifShowAll, true)) }
    var notifGrup = remember { mutableStateOf<String?>(null) }

    val breakpoint = rememberBreakpoint()
    val notifListVm = AppData.notifListVm
    LaunchedEffect(true) {
        notifListVm.getNotifList()
    }


    PageBox(
        modifier = Modifier
            .overflow(Overflow.Hidden)
            .pointerEvents(PointerEvents.Auto)
    ) {
        Column(
            modifier = MainPageStyle.toModifier()
                .overflow(Overflow.Hidden),
            verticalArrangement = Arrangement.Top,
            horizontalAlignment = Alignment.CenterHorizontally
        ) {

            Row(
                modifier = Modifier.fillMaxSize()
            ) {
                if (breakpoint >= Breakpoint.SM) {
                    LeftPane(breakpoint)
                }
                Column(
                    modifier = Modifier
                        .fillMaxWidth(if (breakpoint > Breakpoint.SM) 44.percent else 100.percent)
                        .minWidth(if (breakpoint > Breakpoint.SM) 320.px else 260.px)
                        .maxWidth(if (breakpoint > Breakpoint.SM) 560.px else 1000.px)
                        .thenIf(breakpoint <= Breakpoint.SM){
                            Modifier.overflow { y(Overflow.Auto) }
                                .overflow { x(Overflow.Hidden) }
                                .fillMaxHeight()
                                .scrollBehavior(ScrollBehavior.Smooth)
                                .styleModifier {
                                    property("scrollbar-width", "thin")
                                }
                        }
                        .thenIf(breakpoint > Breakpoint.SM){
                            Modifier.overflow(Overflow.Hidden)
                        }
                        .padding(leftRight = 4.px)
                ) {
                    AppCalendarView(modifier = Modifier.margin(6.px), notifListVm)
                    if (breakpoint > Breakpoint.SM) {
                        NotifGroups(notifListVm, notifGrup, showTmm.value)
                    }
                    if (breakpoint <= Breakpoint.SM) {
                        NotifList(
                            modifier = Modifier.fillMaxWidth()
                                .margin(left = 4.px)
                                .padding(top = 6.px, left = 6.px),
                            breakpoint, searchText, showTmm, notifGrup.value,
                            notifListVm, onAddNotif = {
                                showAddNotif = true
                            }
                        )
                    }

                }
                if (breakpoint > Breakpoint.SM) {
                    Box(modifier = Modifier.width(2.px)) { }
                    NotifList(
                        modifier = Modifier.fillMaxWidth(60.percent).margin(top = 6.px, left = 6.px),
                        breakpoint, searchText, showTmm, notifGrup.value,
                        notifListVm,  onAddNotif = {
                            showAddNotif = true
                        }
                    )
                } else {
                    Box(modifier = Modifier.width(4.px)) { }
                }
            }

            if (breakpoint < Breakpoint.SM) {
                BottomNavigation(breakpoint)
            }

        }

        if (showAddNotif) {
            DialogView {
                AddNotif(scope, notifListVm) {
                    showAddNotif = false
                }
            }
        }

    }

}
