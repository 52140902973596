package com.kelimesoft.cowmaster.pages.cowdetails

import androidx.compose.runtime.*
import com.kelimesoft.cowmaster.components.DialogTitle
import com.kelimesoft.cowmaster.components.DialogView
import com.kelimesoft.cowmaster.models.*
import com.kelimesoft.cowmaster.styles.CloseButtonStyle
import com.kelimesoft.cowmaster.styles.DetailButtonStyle
import com.kelimesoft.cowmaster.utils.tr
import com.kelimesoft.cowmaster.viewmodals.AppData
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.ObjectFit
import com.varabyte.kobweb.compose.foundation.layout.*
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.attrsModifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.fa.*
import com.varabyte.kobweb.silk.components.style.toModifier
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.Text

@Composable
fun CowImageViever(image: CowImage, onFav: () -> Unit,
                   onDelete: () -> Unit, onClose: () -> Unit) {
    val shareId = AppData.cowUser?.shareId
    var showDelete by remember { mutableStateOf(false) }
    Column(
        modifier = Modifier
            .fillMaxWidth()
            .maxWidth(480.px)
            .boxShadow(offsetY = 2.px, offsetX = 1.px, color = AppColors.Background)
            .padding(4.px)
            .borderRadius(r = 10.px)
            .backgroundColor(Colors.White)
    ) {

        ImageViewTitle(fav = image.fav == 1, onFav = {
            onFav()
        }, onDelete = {
            showDelete = true
        }, onClose = onClose)

        Box(
            contentAlignment = Alignment.Center,
            modifier = Modifier
                .fillMaxWidth()

        ) {
            Image(
                modifier = Modifier
                    .borderRadius(r = 8.px)
                    .fillMaxSize()
                    .attrsModifier {
                        //on error özelliği ile attr ekledim, resim var ama alınamadı/ başka folder da
                        attr("onerror", "this.onerror=null;this.src=\"${Routes.WebRoot}${Images.noImage}\";")
                    }
                    .objectFit(ObjectFit.Fill),
                src = if (image.img.isNotEmpty()) {
                    "${Routes.ImgRoot}$shareId/${image.img}"
                } else Values.imgPath2 + Images.noImage,

                )

            if (showDelete){
                DialogView{
                    Column(
                        modifier = Modifier
                            .maxWidth(260.px)
                            .boxShadow(offsetY = 2.px, offsetX = 1.px, color = AppColors.Background)
                            .padding(4.px)
                            .borderRadius(r = 8.px)
                            .backgroundColor(Colors.White)
                    ) {
                        Text("Resim Silinecek")
                        Row(horizontalArrangement = Arrangement.SpaceBetween,
                            verticalAlignment = Alignment.CenterVertically,
                            modifier = Modifier.fillMaxWidth()
                        ) {
                            Button(
                                attrs = DetailButtonStyle.toModifier()
                                    .margin(leftRight = 4.px)
                                    .height(28.px)
                                    .maxWidth(160.px)
                                    .onClick {
                                        onFav()
                                    }
                                    .backgroundColor(AppColors.Gray)
                                    .fontWeight(FontWeight.Medium)
                                    .color(Colors.White)
                                    .toAttrs()
                            ) {
                                Row(verticalAlignment = Alignment.CenterVertically) {
                                    Text("İptal".tr())
                                }
                            }

                            Button(
                                attrs = DetailButtonStyle.toModifier()
                                    .margin(leftRight = 4.px)
                                    .height(28.px)
                                    .maxWidth(160.px)
                                    .onClick {
                                        onDelete()
                                    }
                                    .backgroundColor(AppColors.DarkRed)
                                    .fontWeight(FontWeight.Medium)
                                    .color(Colors.White)
                                    .toAttrs()
                            ) {
                                Row(verticalAlignment = Alignment.CenterVertically) {
                                    Text("detay_sil".tr())
                                }
                            }
                        }
                    }

                }
            }
        }


    }

}


@Composable
fun ImageViewTitle(fav: Boolean, onFav: () -> Unit, onDelete: () -> Unit, onClose: () -> Unit){
    Row(modifier = Modifier.fillMaxWidth()
        .margin(top = 6.px, bottom = 3.px)
        .padding(leftRight = 7.px),
        verticalAlignment = Alignment.CenterVertically
    ) {

        if (fav.not()) {
            Button(
                attrs = DetailButtonStyle.toModifier()
                    .margin(leftRight = 4.px)
                    .height(24.px)
                    .maxWidth(160.px)
                    .onClick {
                        onFav()
                    }
                    .backgroundColor(AppColors.NumYellow)
                    .fontWeight(FontWeight.Medium)
                    .color(Colors.White)
                    .toAttrs()

            ) {
                Row(verticalAlignment = Alignment.CenterVertically) {
                    FaStar(
                        modifier = Modifier
                            .margin(right = 4.px)
                            .color(Colors.White),
                        size = IconSize.SM
                    )
                    Text("Favori Yap".tr())
                }
            }
        }

        Button(
            attrs = DetailButtonStyle.toModifier()
                .margin(leftRight = 4.px)
                .height(24.px)
                .maxWidth(160.px)
                .onClick {
                    onDelete()
                }
                .backgroundColor(AppColors.DarkRed)
                .fontWeight(FontWeight.Medium)
                .color(Colors.White)
                .toAttrs()

        ) {
            Row(verticalAlignment = Alignment.CenterVertically) {
                FaTrashCan(
                    modifier = Modifier
                        .margin(right = 4.px)
                        .color(Colors.White),
                    size = IconSize.SM
                )
                Text("detay_sil".tr())
            }
        }

        Spacer()



        Box(modifier = CloseButtonStyle.toModifier()
            .onClick {
                onClose()
            }
        ) {
            FaIcon("close", modifier = Modifier
                , IconCategory.SOLID,
                size = IconSize.XL
            )
        }
    }
}