package com.kelimesoft.cowmaster.components


import androidx.compose.runtime.Composable
import com.kelimesoft.cowmaster.models.AppColors
import com.kelimesoft.cowmaster.styles.CloseButtonStyle
import com.kelimesoft.cowmaster.styles.TextButtonStyle
import com.kelimesoft.cowmaster.utils.tr
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.*
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.icons.fa.FaIcon
import com.varabyte.kobweb.silk.components.icons.fa.IconCategory
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.style.toModifier
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text

data class AlertInfo(
    val title: String = "",
    var text: String = "",
    val cancelText: String = "shared_iptal".tr(),
    val confirmText: String = "",
    var onClose: () -> Unit,
    var onConFirm: () -> Unit
)


@Composable
fun AlertBox(alert: AlertInfo){
    Column(modifier = Modifier.width(300.px)
        .backgroundColor(Colors.White)
        .borderRadius(r = 10.px)
        .padding(10.px)
    ){
        AlertTitle(alert.title, alert.onClose)

        P(
            attrs = Modifier
                .color(AppColors.TextColor)
                .toAttrs()
        ) {
            Text(alert.text)
        }
        Row(modifier = Modifier.fillMaxWidth()
            .margin(top = 8.px),
            verticalAlignment = Alignment.CenterVertically,
            horizontalArrangement = Arrangement.SpaceBetween
        ) {
            if (alert.cancelText.isNotEmpty()){
                Button(attrs = TextButtonStyle.toModifier()
                    .color(AppColors.TextColor)
                    .fontWeight(FontWeight.SemiBold)
                    .fontSize(14.px)
                    .onClick {
                        alert.onClose()
                    }
                    .toAttrs()) {
                    Text(alert.cancelText)
                }
            }else{
                Spacer()
            }

            Button(attrs = TextButtonStyle.toModifier()
                .color(Colors.Red)
                .fontWeight(FontWeight.SemiBold)
                .fontSize(14.px)
                .onClick {
                    alert.onConFirm()
                }
                .toAttrs()) {
                Text(alert.confirmText)
            }
        }


    }
}

@Composable
fun AlertTitle(title: String, onClose: () -> Unit){
    Row(modifier = Modifier.fillMaxWidth()
        .margin(top = 6.px, bottom = 3.px)
        .padding(leftRight = 6.px),
        verticalAlignment = Alignment.CenterVertically,
        horizontalArrangement = Arrangement.SpaceBetween
    ) {
        Span(attrs = Modifier
            .fontSize(14.px)
            .fontWeight(FontWeight.SemiBold)
            .color(AppColors.TextColor)
            .toAttrs()) {
            Text(title)
        }
        Spacer()
        Box(modifier = CloseButtonStyle.toModifier().then(
            Modifier.padding(9.px)
                .backgroundColor(Colors.Red.copyf(alpha = 0.6f))
            .onClick {
                onClose()
            }
        )){
            FaIcon("close", modifier = Modifier
                , IconCategory.SOLID,
                size = IconSize.SM
            )
        }
    }
}