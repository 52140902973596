import androidx.compose.runtime.CompositionLocalProvider
import com.varabyte.kobweb.core.AppGlobals
import com.varabyte.kobweb.navigation.RoutePrefix
import com.varabyte.kobweb.navigation.Router
import com.varabyte.kobweb.navigation.UpdateHistoryMode
import com.varabyte.kobweb.silk.defer.renderWithDeferred
import kotlinx.browser.document
import kotlinx.browser.window
import org.jetbrains.compose.web.renderComposable

public fun main() {
    RoutePrefix.set("")
    val router = Router()
    com.varabyte.kobweb.core.init.initKobweb(router) { ctx ->
        ctx.router.register("/") { com.kelimesoft.cowmaster.pages.HomePage() }
        ctx.router.register("/bildirim") { com.kelimesoft.cowmaster.pages.NotifPage() }
        ctx.router.register("/cikan") { com.kelimesoft.cowmaster.pages.DismissPage() }
        ctx.router.register("/durumliste") { com.kelimesoft.cowmaster.pages.EventPage() }
        ctx.router.register("/fileupload") { com.kelimesoft.cowmaster.pages.FileUpload() }
        ctx.router.register("/gelir-gider") { com.kelimesoft.cowmaster.pages.DairyFinance() }
        ctx.router.register("/liste") { com.kelimesoft.cowmaster.pages.CowListPage() }
        ctx.router.register("/liste/{grup}") { com.kelimesoft.cowmaster.pages.CowListGrup() }
        ctx.router.register("/ozet") { com.kelimesoft.cowmaster.pages.SummaryPage() }
        ctx.router.register("/page404") { com.kelimesoft.cowmaster.pages.PageNotFound() }
        ctx.router.register("/profil") { com.kelimesoft.cowmaster.pages.ProfilePage() }
        ctx.router.register("/sut") { com.kelimesoft.cowmaster.pages.MilkPage() }

    }
    router.setLegacyRouteRedirectStrategy(Router.LegacyRouteRedirectStrategy.DISALLOW)
    router.addRouteInterceptor {
        path = path.removeSuffix(".html").removeSuffix(".htm")
    }

    com.varabyte.kobweb.silk.init.additionalSilkInitialization = { ctx ->
        com.varabyte.kobweb.silk.init.initSilkWidgets(ctx)
        com.varabyte.kobweb.silk.init.initSilkWidgetsKobweb(ctx)
        ctx.theme.registerComponentStyle(com.kelimesoft.cowmaster.styles.MainButtonStyle)
        ctx.theme.registerComponentStyle(com.kelimesoft.cowmaster.styles.MobilAddButtonStyle)
        ctx.theme.registerComponentStyle(com.kelimesoft.cowmaster.styles.TextButtonStyle)
        ctx.theme.registerComponentStyle(com.kelimesoft.cowmaster.styles.LeftPaneButtonStyle)
        ctx.theme.registerComponentStyle(com.kelimesoft.cowmaster.styles.BarButtonStyle)
        ctx.theme.registerComponentStyle(com.kelimesoft.cowmaster.styles.DetailButtonStyle)
        ctx.theme.registerComponentStyle(com.kelimesoft.cowmaster.styles.DropDownButtonStyle)
        ctx.theme.registerComponentStyle(com.kelimesoft.cowmaster.styles.NumCardStyle)
        ctx.theme.registerComponentStyle(com.kelimesoft.cowmaster.styles.NumRowStyle)
        ctx.theme.registerComponentStyle(com.kelimesoft.cowmaster.styles.DisposedSumStyle)
        ctx.theme.registerComponentStyle(com.kelimesoft.cowmaster.styles.DropMenuItemStyle)
        ctx.theme.registerComponentStyle(com.kelimesoft.cowmaster.styles.PayMethodStyle)
        ctx.theme.registerComponentStyle(com.kelimesoft.cowmaster.styles.CloseButtonStyle)
        ctx.theme.registerComponentStyle(com.kelimesoft.cowmaster.styles.RowButtonStyle)
        ctx.theme.registerComponentStyle(com.kelimesoft.cowmaster.styles.BottomNavButtonStyle)
        ctx.theme.registerComponentStyle(com.kelimesoft.cowmaster.styles.NotifSelectButtonStyle)
        ctx.theme.registerComponentStyle(com.kelimesoft.cowmaster.styles.HerdSelectButtonStyle)
        ctx.theme.registerComponentStyle(com.kelimesoft.cowmaster.styles.CowRowStyle)
        ctx.theme.registerComponentStyle(com.kelimesoft.cowmaster.styles.InputStyles)
        ctx.theme.registerComponentStyle(com.kelimesoft.cowmaster.styles.InputCaptchaStyle)
        ctx.theme.registerComponentStyle(com.kelimesoft.cowmaster.styles.SearchBarStyles)
        ctx.theme.registerComponentStyle(com.kelimesoft.cowmaster.styles.CowInputStyle)
        ctx.theme.registerComponentStyle(com.kelimesoft.cowmaster.styles.TopluInputStyle)
        ctx.theme.registerComponentStyle(com.kelimesoft.cowmaster.styles.DateSelectStyle)
        ctx.theme.registerComponentStyle(com.kelimesoft.cowmaster.styles.MainPageStyle)
        ctx.theme.registerComponentStyle(com.kelimesoft.cowmaster.styles.PaneCardStyle)
        ctx.theme.registerComponentStyle(com.kelimesoft.cowmaster.styles.StaggeredItemStyle)
        ctx.theme.registerComponentStyle(com.kelimesoft.cowmaster.styles.SummaryCardstyle)
        ctx.theme.registerComponentStyle(com.kelimesoft.cowmaster.styles.DetayCardstyle)
        ctx.theme.registerComponentStyle(com.kelimesoft.cowmaster.styles.ClickableSummaryCardstyle)
        ctx.theme.registerComponentStyle(com.kelimesoft.cowmaster.styles.ErrorTextStyle)
        ctx.theme.registerComponentStyle(com.kelimesoft.cowmaster.styles.SuccesTextStyle)
        ctx.theme.registerComponentStyle(com.kelimesoft.cowmaster.styles.DetailInfoTitleStyle)
        ctx.theme.registerComponentStyle(com.kelimesoft.cowmaster.styles.DetailInfoDataStyle)
        ctx.theme.registerComponentStyle(com.kelimesoft.cowmaster.styles.CowMilkTitleStyle)
        ctx.theme.registerComponentStyle(com.kelimesoft.cowmaster.styles.CaptchaStyle)
        com.kelimesoft.cowmaster.initializeBreakpoints(ctx)
    }

    // For SEO, we may bake the contents of a page in at build time. However, we will
    // overwrite them the first time we render this page with their composable, dynamic
    // versions. Think of this as poor man's hydration :)
    // See also: https://en.wikipedia.org/wiki/Hydration_(web_development)
    router.navigateTo(window.location.href.removePrefix(window.location.origin),
            UpdateHistoryMode.REPLACE)

    val root = document.getElementById("root")!!
    while (root.firstChild != null) { root.removeChild(root.firstChild!!) }

    AppGlobals.initialize(mapOf("title" to "CowMaster App"))
    renderComposable(rootElementId = "root") {
        com.kelimesoft.cowmaster.AppEntry {
            router.renderActivePage { renderWithDeferred { it() } }
        }
    }
}
