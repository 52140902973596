package com.kelimesoft.cowmaster.pages.cowdetails

import androidx.compose.runtime.*
import com.kelimesoft.cowmaster.components.*
import com.kelimesoft.cowmaster.styles.CowInputStyle
import com.kelimesoft.cowmaster.styles.DropMenuItemStyle
import com.kelimesoft.cowmaster.styles.MainButtonStyle
import com.kelimesoft.cowmaster.styles.TextButtonStyle
import com.kelimesoft.cowmaster.utils.*
import com.kelimesoft.cowmaster.viewmodals.AppData
import com.kelimesoft.cowmaster.viewmodals.CowDetailViewModel
import com.kelimesoft.cowmaster.components.DropDownMenu
import com.kelimesoft.cowmaster.models.*
import com.kelimesoft.cowmaster.pages.closeDropDowns
import com.kelimesoft.cowmaster.utils.dateToDbLongStr
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.WhiteSpace
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.attrsModifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.forms.Switch
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.fa.FaCaretDown
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.style.toModifier
import kotlinx.browser.document
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.ExperimentalComposeWebApi
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.*
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLInputElement
import kotlin.js.Date

@Composable
fun AddDurum(scope: CoroutineScope, detailVM: CowDetailViewModel, onClose: () -> Unit) {
    var durumList by remember { mutableStateOf(listOf<Events>()) }
    var errorText by remember { mutableStateOf("") }
    var eventSelect: Events by remember { mutableStateOf(Events.None) }
    var eventDate by remember { mutableStateOf(Date().dateToDbLongStr()) }
    var eventDeger: MutableState<Int> = remember { mutableStateOf(0) }
    var eventInfo by remember { mutableStateOf("") }
    var eventDetay by remember { mutableStateOf("") }

    var einfoText: String? =
        when(eventSelect){
            Events.AY -> "adddurum_asiadi".tr()
            Events.TY -> "adddurum_bogaadi".tr()
            Events.HG -> "adddurum_hastaadi".tr()
            else -> null
        }

    LaunchedEffect(true) {
        val today = Date()
        var durumlar: List<Events>
        detailVM.cowDetay?.let { detay ->
            val kacAylik = dateDiff(today, Date(detay.birth), "m")
            if (detay.gender == 0 && kacAylik > 8) {
                durumlar = Events.disiEvents()
                val sonThmTrh = detailVM.cowEvents?.firstOrNull { it.cat == "TY" }
                if (sonThmTrh != null) {
                    val tohumgun = dateDiff(today, Date(sonThmTrh.date), "d");
                    if (tohumgun in 25..150) {
                        durumlar = Events.tohumluEvents()
                    } else if (tohumgun in 40..260) {
                        durumlar = Events.gebeEvents()
                    }
                }
            } else {
                durumlar = if (AppData.cowListVM.selectedCow?.snf == 1) {
                    Events.buzagiEvents()
                } else {
                    Events.tosunEvents()
                }
            }
            durumList = durumlar
        }

    }

    fun saveEvent() {
        if (!Funcs.checkForm("form-event")) {
            return
        }

        //date.toLocalDateTime(TimeZone.currentSystemDefault())
        val nEvent = NewEvent(
            hid = detailVM.cowDetay!!.hid,
            cat = eventSelect.name,
            date = Date(eventDate).dateToDbLongStr(),
            deger = eventDeger.value,
            info = eventInfo,
            notes = eventDetay,
        )
        //console.log("event:", nEvent.toString())
        scope.launch {
            val res = detailVM.addNewEvent(nEvent)
            if (res == null) {
                onClose()
            } else {
                errorText = res
            }
        }
    }

    Column(
        modifier = Modifier
            .fillMaxWidth()
            .maxWidth(460.px)
            .boxShadow(offsetY = 2.px, offsetX = 1.px, color = AppColors.Background)
            .padding(4.px)
            .borderRadius(r = 10.px)
            .backgroundColor(Colors.White)
    ) {

        DialogTitle("adddurum_add".tr(), onClose = onClose)

        Column(
            Modifier
                .fillMaxWidth()
                .padding(topBottom = 6.px, leftRight = 8.px)
        ) {
            Box(
                Modifier.fillMaxWidth(),
                contentAlignment = Alignment.Center
            ) {
                DropDownMenu(
                    menuModifier = Modifier.fillMaxWidth(),
                    butonModifier = CowInputStyle.toModifier()
                        .fillMaxWidth()
                        .padding(6.px)
                        .margin(topBottom = 8.px)
                        .whiteSpace(WhiteSpace.NoWrap)
                        .onClick {
                            it.stopPropagation()
                            val menu = document.getElementById("add-drop") as HTMLDivElement
                            if (menu.style.display == "block") {
                                menu.style.display = "none"
                            } else {
                                closeDropDowns()
                                menu.style.display = "block"
                            }
                        },
                    id = "add-drop",
                    content = {
                        Row(
                            modifier = Modifier.fillMaxWidth(),
                            verticalAlignment = Alignment.CenterVertically
                        ) {
                            Image(
                                modifier = Modifier.size(20.px)
                                    .margin(right = 10.px),
                                src = Values.imgPath2 + eventSelect.icon
                            )
                            Text(eventSelect.text)
                            Spacer()
                            FaCaretDown(
                                modifier = Modifier
                                    .color(AppColors.GrayDark),
                                size = IconSize.LG
                            )

                        }
                    }) {
                    durumList.forEach { evt ->
                        Button(
                            attrs = DropMenuItemStyle.toModifier()
                                .padding(3.px)
                                .color(AppColors.TextColor)
                                .fontSize(14.px)
                                .margin(bottom = 2.px)
                                .onClick {
                                    if (eventSelect != evt){
                                        try {
                                            (document.getElementById("event-info") as HTMLInputElement).value = ""
                                        }catch (_: Exception){}
                                        einfoText = null
                                        eventInfo = ""
                                        eventSelect = evt
                                        errorText = ""
                                    }
                                }
                                .toAttrs()) {
                            Row(
                                modifier = Modifier.fillMaxWidth(),
                                verticalAlignment = Alignment.CenterVertically
                            ) {

                                Image(
                                    modifier = Modifier.size(20.px)
                                        .margin(right = 10.px),
                                    src = Values.imgPath2 + evt.icon
                                )
                                Text(evt.text)
                            }
                        }
                    }
                }
                if (errorText.isNotEmpty()) {
                    ErrorText(errorText)
                }

            }

            Form(
                attrs = Modifier
                    .id("form-event")
                    .fillMaxWidth()
                    .toAttrs()
            ) {
                Column(
                    modifier = Modifier.fillMaxWidth(),
                    horizontalAlignment = Alignment.Start
                ) {
                    Span(
                        attrs = Modifier
                            .fontSize(12.px)
                            .margin(top = 4.px)
                            .padding(1.px)
                            .color(AppColors.TextColor.copyf(alpha = 0.6f))
                            .toAttrs()
                    ) {
                        Text("adddurum_date".tr())
                    }
                    Input(
                        type = InputType.DateTimeLocal,
                        attrs = CowInputStyle.toModifier()
                            .id("event-date")
                            .padding(7.px)
                            .attrsModifier {
                                attr("required", "true")
                                attr("value", Date().dateToDbLongStr())
                                attr("max", Date().dateToDbLongStr())
                            }
                            .margin(bottom = 10.px)
                            .toAttrs()
                            {
                                onInput {
                                    eventDate = it.value
                                }
                            }
                    )
                }
                einfoText?.let {
                    Input(
                        type = InputType.Text,
                        attrs = CowInputStyle.toModifier()
                            .id("event-info")
                            .attrsModifier {
                                attr("required", "true")
                                attr("name", "einfo")
                                attr("placeholder", einfoText ?: "")
                            }
                            .margin(bottom = 10.px)
                            .toAttrs()
                            {
                                onInput {
                                    eventInfo = it.value
                                }
                            }
                    )
                }


                if (eventSelect == Events.AB) {
                    DusukResult(eventDeger)
                } else if (eventSelect == Events.KO) {
                    TartiResult(eventDeger)
                } else if (eventSelect == Events.GK) {
                    GebelikSonuc(eventDeger)
                }

                Input(
                    type = InputType.Text,
                    attrs = CowInputStyle.toModifier()
                        .attrsModifier {
                            attr("name", "aciklama")
                            attr("placeholder", "adddurum_ackl".tr())
                        }
                        .margin(bottom = 10.px)
                        .toAttrs()
                        {
                            onInput {
                                eventDetay = it.value
                            }
                        }
                )


            }
            Row(
                modifier = Modifier.fillMaxWidth()
                    .margin(top = 20.px),
                verticalAlignment = Alignment.CenterVertically
            ) {
                Button(
                    attrs = TextButtonStyle.toModifier()
                        .fontSize(16.px)
                        .color(AppColors.DarkRed)
                        .onClick {
                            it.preventDefault()
                            onClose()
                        }
                        .toAttrs()

                ) {
                    Text("shared_iptal".tr())
                }


                Spacer()
                Button(
                    attrs = MainButtonStyle.toModifier()
                        //.backgroundColor(AppColors.Red)
                        .onClick {
                            it.preventDefault()
                            if (eventSelect == Events.None) {
                                errorText = "adddurum_select".tr()
                            } else {
                                saveEvent()
                            }
                        }
                        .toAttrs()

                ) {
                    Text("adddurum_add".tr())
                }
            }

        }


    }

}


@Composable
fun DusukResult(eventDeger: MutableState<Int>) {
    Row(
        modifier = Modifier.fillMaxWidth()
            .margin(bottom = 10.px),
        verticalAlignment = Alignment.CenterVertically
    ) {
        Switch(
            checked = eventDeger.value == 1,
            onCheckedChange = {
                eventDeger.value = if (it) 1 else 0
            },

            )
        Span(
            attrs = Modifier
                .margin(left = 8.px)
                .fontSize(14.px)
                .color(AppColors.TextColor)
                .toAttrs()
        ) {
            Text("adddurum_sagmaldur".tr())
            if (eventDeger.value == 1) {
                Span(
                    attrs = Modifier
                        .color(AppColors.PrimaryDark)
                        .fontWeight(FontWeight.Medium)
                        .toAttrs()
                ) {
                    Text("adddurum_sagmal".tr())
                }
            } else {
                Span(
                    attrs = Modifier
                        .color(AppColors.GrayDark)
                        .fontWeight(FontWeight.Medium)
                        .toAttrs()
                ) {
                    Text("adddurum_kuru".tr())
                }
            }
        }
    }
}


@Composable
fun TartiResult(eventDeger: MutableState<Int>) {
    Row(
        modifier = Modifier.fillMaxWidth()
            .margin(bottom = 10.px),
        verticalAlignment = Alignment.CenterVertically
    ) {
        Span(
            attrs = Modifier
                .whiteSpace(WhiteSpace.NoWrap)
                .margin(right = 10.px)
                .fontSize(14.px)
                .color(AppColors.TextColor)
                .toAttrs()
        ) {
            Text("adddurum_tarti".tr())
        }

        Input(
            type = InputType.Number,
            attrs = CowInputStyle.toModifier()
                .attrsModifier {
                    attr("required", "true")
                    attr("placeholder", "0")
                }
                .toAttrs()
                {
                    onInput {
                        eventDeger.value = it.value as Int
                        //console.log("deger:", eventDeger.value)
                    }
                }
        )
    }
}

@OptIn(ExperimentalComposeWebApi::class)
@Composable
fun GebelikSonuc(eventDeger: MutableState<Int>) {
    Row(
        modifier = Modifier.fillMaxWidth()
            .margin(bottom = 10.px),
        verticalAlignment = Alignment.CenterVertically
    ) {

        RadioGroup(
            checkedValue = if (eventDeger.value == 1) "pozitif" else "negatif",
        ) {
            Span(
                attrs = Modifier
                    .margin(right = 10.px)
                    .fontSize(14.px)
                    .color(AppColors.TextColor)
                    .toAttrs()
            ) {
                Text("adddurum_gbtest".tr())
            }

            Row(
                Modifier
                    .margin(right = 20.px)
                    .cursor(Cursor.Pointer),
                verticalAlignment = Alignment.CenterVertically
            ) {
                RadioInput(
                    value = "pozitif",
                    attrs = Modifier
                        .color(AppColors.PrimaryDark)
                        .size(16.px)
                        .toAttrs()
                        {
                            onChange {
                                eventDeger.value = 1
                            }
                        }
                )

                Span(
                    attrs = Modifier
                        .margin(left = 3.px)
                        .fontSize(14.px)
                        .color(AppColors.PrimaryDark)
                        .onClick {
                            eventDeger.value = 1
                        }
                        .toAttrs()
                ) {
                    Text("adddurum_pozitif".tr())
                }
            }

            Row(
                Modifier
                    .cursor(Cursor.Pointer),
                verticalAlignment = Alignment.CenterVertically
            ) {
                RadioInput(
                    value = "negatif",
                    attrs = Modifier
                        .color(AppColors.Red)
                        .size(16.px)
                        .toAttrs()
                        {
                            onChange {
                                eventDeger.value = 0
                            }
                        }
                )

                Span(
                    attrs = Modifier
                        .margin(left = 3.px)
                        .fontSize(14.px)
                        .color(AppColors.DarkRed)
                        .onClick {
                            eventDeger.value = 0
                        }
                        .toAttrs()
                ) {
                    Text("adddurum_negatif".tr())
                }
            }

        }

    }
}
