package com.kelimesoft.cowmaster.components

import androidx.compose.runtime.*
import com.kelimesoft.cowmaster.components.CircleBox
import com.kelimesoft.cowmaster.components.DialogTitle
import com.kelimesoft.cowmaster.components.ErrorText
import com.kelimesoft.cowmaster.components.VDivider
import com.kelimesoft.cowmaster.models.AppColors
import com.kelimesoft.cowmaster.models.HerdItem

import com.kelimesoft.cowmaster.styles.InputStyles
import com.kelimesoft.cowmaster.styles.MainButtonStyle
import com.kelimesoft.cowmaster.styles.TextButtonStyle
import com.kelimesoft.cowmaster.utils.Funcs
import com.kelimesoft.cowmaster.utils.tr
import com.kelimesoft.cowmaster.viewmodals.AppData
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.*
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.attrsModifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.icons.fa.FaCheck
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.components.style.toModifier
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.*

@Composable

fun AddNewHerd(herd: HerdItem? = null,
               onClose: () -> Unit
) {
    val scope = rememberCoroutineScope()
    var errorText by remember { mutableStateOf("") }

    var herdName by remember { mutableStateOf("") }
    var colorIndex by remember { mutableStateOf(-1) }

    LaunchedEffect(herd != null){
        herd?.name?.let {
            herdName = it
        }
        herd?.color?.let {
            colorIndex = it -1
        }
    }
    Column(
        modifier = Modifier
            .backgroundColor(Colors.White)
            .padding(topBottom = 10.px, leftRight = 6.px)
            .borderRadius(r = 8.px),
        horizontalAlignment = Alignment.CenterHorizontally

    )
    {
        DialogTitle(if (herd == null) "addherd_add".tr() else "addherd_edit".tr()) {
            onClose()
        }
        VDivider()


        Box(modifier = Modifier
            .fillMaxWidth()
            .padding(leftRight = 6.px),
            contentAlignment = Alignment.Center
            ) {
            TextInput(
                value = herdName,
                attrs = InputStyles.toModifier()
                    .attrsModifier {
                        attr("type", "text")
                        attr("name", "herdname")
                        attr("placeholder", "addherd_ad".tr())
                        attr("required", "true")
                    }
                    .toAttrs()
                    {
                        onInput {
                            herdName = it.value
                        }
                    }
            )
        }
        if (errorText.isNotEmpty()){
            scope.launch {
                delay(3000)
                errorText = ""
            }
            ErrorText(errorText)
        }

        Span(attrs = Modifier
            .fillMaxWidth()
            .margin(top = 8.px)
            .fontSize(12.px)
            .textAlign(TextAlign.Center)
            .color(AppColors.GrayDark)
            .toAttrs()){
            Text("addherd_renk".tr())
        }
        SimpleGrid(
            modifier = Modifier
                .margin(bottom = 8.px)
                .padding(leftRight = 8.px),
            numColumns = numColumns(base = 6)
        ) {
            AppColors.herdColorList.forEachIndexed { index, color ->
                CircleBox(
                    modifier = Modifier
                        .onClick {
                            colorIndex = index
                        }
                        .margin(4.px)
                        .size(40.px)
                        .backgroundColor(color)
                ) {
                    if (colorIndex == index) {
                        FaCheck(
                            modifier = Modifier.color(Colors.White),
                            size = IconSize.XL
                        )
                    }
                }

            }
        }

        Row(
            modifier = Modifier.fillMaxWidth()
                .padding(8.px),
            verticalAlignment = Alignment.CenterVertically,
            horizontalArrangement = Arrangement.End

        ) {
            Button(
                attrs = TextButtonStyle.toModifier()
                    .onClick {
                        onClose()
                    }
                    .fontSize(16.px)
                    .color(Colors.Red)
                    .toAttrs()
            ) {
                Text("shared_iptal".tr())
            }

            Spacer()

            Button(
                attrs = MainButtonStyle.toModifier()
                    .fontSize(16.px)
                    .onClick {
                        if (herdName.isEmpty()){
                            errorText = "addherd_errgrup".tr()
                            return@onClick
                        }else if (colorIndex < 0){
                            errorText = "addherd_errrenk".tr()
                            return@onClick
                        }
                        scope.launch {
                            var nHerd = HerdItem(name = herdName, color = colorIndex + 1)
                            if (herd != null){
                                if (herd.color != nHerd.color || herd.name != nHerd.name){
                                    //değişlik yapıldıysa edit herd olacak
                                    nHerd.id = herd.id
                                    nHerd.count = herd.count
                                    AppData.summaryViewModel.saveHerd(nHerd)
                                }
                            }else{
                                //add new herd id -1
                                AppData.summaryViewModel.saveHerd(nHerd)
                            }
                            onClose()
                        }
                    }
                    .toAttrs()
            ) {
                Text(if (herd == null) "addherd_addnow".tr() else "addherd_kaydet".tr())
            }
        }


    }
}


