package com.kelimesoft.cowmaster.pages.cowlist


import androidx.compose.runtime.*
import com.kelimesoft.cowmaster.components.DialogType
import com.kelimesoft.cowmaster.components.DialogView
import com.kelimesoft.cowmaster.components.LeftPane
import com.kelimesoft.cowmaster.components.PageBox
import com.kelimesoft.cowmaster.models.AppColors
import com.kelimesoft.cowmaster.models.Values
import com.kelimesoft.cowmaster.styles.MainPageStyle
import com.kelimesoft.cowmaster.utils.tr
import com.kelimesoft.cowmaster.viewmodals.AppData
import com.kelimesoft.cowmaster.components.AlertBox
import com.kelimesoft.cowmaster.components.AlertInfo
import com.kelimesoft.cowmaster.components.BottomNavigation
import com.kelimesoft.cowmaster.components.MobileAddButton
import com.kelimesoft.cowmaster.pages.closeDropDowns
import com.kelimesoft.cowmaster.pages.cowdetails.CowDetayView
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.css.PointerEvents
import com.varabyte.kobweb.compose.foundation.layout.*
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.core.AppGlobals.get
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import kotlinx.browser.document
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.w3c.dom.events.EventListener


@Composable
fun CowList() {
    val breakpoint = rememberBreakpoint()
    val cowListVM = AppData.cowListVM
    var showAddCows by remember { mutableStateOf(false) }
    var showAddSingleCow by remember { mutableStateOf(false) }
    var boxAlert: AlertInfo? by remember { mutableStateOf(null) }
    var cowUser = AppData.cowUser

    var detailCowDialog by remember { mutableStateOf(false) }

    fun checkCanAddCows(): Boolean {
        val canAdd = (cowUser?.hayvanLimit ?: 0) < AppData.summaryViewModel.allCows
        if (canAdd) {
            boxAlert = AlertInfo("Hayvan Limit",
                "Paketinizin hayvan limiti dolmuştur, daha fazla hayvan ekleyebilek için paketinizi yükseltiniz.".tr(),
                "",
                "shared_kapat".tr(),
                onClose = {
                    boxAlert = null
                }) {
                boxAlert = null
            }
            return false
        }
        AppData.cowUser?.canEditCattle?.let { edit ->
            if (!edit) {
                boxAlert = AlertInfo("shared_edityetki".tr(),
                    "shared_yetkitext".tr(), "", "shared_kapat".tr(), onClose = {
                        boxAlert = null
                    }) {
                    boxAlert = null
                }
                return false
            }
        }
        return true
    }

    LaunchedEffect(true) {
        cowListVM.getList()
    }

    PageBox(
        modifier = Modifier
            .overflow(Overflow.Hidden)
            .pointerEvents(PointerEvents.Auto)
    ) {
        Column(
            modifier = MainPageStyle.toModifier()
                .overflow(Overflow.Hidden)
                .onClick {
                    closeDropDowns()
                },
            verticalArrangement = Arrangement.Top,
            horizontalAlignment = Alignment.CenterHorizontally
        )
        {
            Row(
                modifier = Modifier.fillMaxSize()
                    .overflow(Overflow.Hidden)
            ) {
                if (breakpoint >= Breakpoint.SM) {
                    LeftPane(breakpoint, Values.imgPath2)
                }
                ListContent(cowListVM, breakpoint, onSelect = {
                    detailCowDialog = breakpoint <= Breakpoint.SM
                }) {
                    if (checkCanAddCows()) {
                        //limdilik single add olacak normalde
                        //showAddCows = true
                        showAddSingleCow = true
                    }
                }
            }
            if (breakpoint < Breakpoint.SM) {
                Box(Modifier.height(Values.bottomBarHeight)) { }
                Spacer()
                BottomNavigation(breakpoint, Values.imgPath2)
            }
        }

        if (breakpoint <= Breakpoint.SM) {
            MobileAddButton(
                modifier = Modifier
                    .margin(12.px)
                    .bottom(if (breakpoint == Breakpoint.SM) 20.px else Values.bottomBarHeight)
                    .onClick {
                        if (checkCanAddCows()) {
                            showAddSingleCow = true
                        }

                    },
                "shared_add".tr()
            )
        }

        if (showAddCows) {
            DialogView {
                AddCattleList {
                    showAddCows = false
                }
            }
        }
        if (showAddSingleCow) {
            DialogView(type = DialogType.BottomSheet) {
                AddSingleCow {
                    showAddSingleCow = false

                }
            }
        }
        if (detailCowDialog && breakpoint <= Breakpoint.SM) {
            DialogView(type = DialogType.BottomSheet) {
                Column(
                    modifier = Modifier.fillMaxWidth(98.percent)
                        .maxHeight(96.percent)
                        .zIndex(1)
                        .backgroundColor(AppColors.Background)
                        .borderRadius(topLeft = 8.px, topRight = 8.px, bottomLeft = 6.px, bottomRight = 6.px)
                        .boxShadow(2.px, (-1).px, 1.px, color = AppColors.Gray.copyf(0.4f))
                        .overflow(Overflow.Hidden)
                        .minHeight(400.px),
                    verticalArrangement = Arrangement.Top
                ) {
                    CowDetayView(cowListVM, true) {
                        detailCowDialog = false
                    }
                }
            }
        }
        if (boxAlert != null) {
            DialogView {
                AlertBox(boxAlert!!)
            }
        }
    }

}


@Composable
fun ObserLastListItem(
    loadItems: () -> Unit
) {
    val listener = remember {
        EventListener {
            val lazyHeight = document.getElementById("lazy-column")?.clientHeight
            val elementRect = document.getElementById("last-item")?.getBoundingClientRect()
            if (elementRect != null) {
                if (elementRect.top < ((lazyHeight ?: 100) + 120)) {
                    loadItems()
                }
            }
        }
    }

    LaunchedEffect(true) {
        val lzyCol = document.getElementById("lazy-column")
        lzyCol?.addEventListener(type = "scroll", listener)
    }

    DisposableEffect(true) {
        onDispose {
            val lzyCol = document.getElementById("lazy-column")
            lzyCol?.removeEventListener(type = "scroll", callback = listener)
        }
    }
}



