package com.kelimesoft.cowmaster.utils

import com.kelimesoft.cowmaster.utils.Funcs

object Trans {
    var userLang: String = Funcs.getLanguage()
    fun getString(str: String): String {
        if (userLang == "tr") {
            return trDict[str] ?: str
        }
        return enDict[str] ?: str
    }

    private val enDict = mapOf(
        "shared_tmm" to "OK",
        "shared_iptal" to "Cancel",
        "shared_save" to "Save",
        "shared_sil" to "Delete",
        "shared_kapat" to "Close",
        "shared_select" to "Select",
        "shared_add" to "+ Add",
        "shared_male" to "Male",
        "shared_female" to "Female",
        "shared_herdselect" to "Select Herd",
        "shared_addgrup" to "+Add New Group",
        "shared_edityetki" to "Editing Privileges",
        "shared_yetkitext" to "Cow Master Web App is only editable with MultiUser Packages.\n" +
                "You can upgrade to multiuser packages through mobile Applications of CowMaster",

        "error_nouser" to "User email not registered!",
        "error_password" to "Password incorrect!",
        "error_cowdb0" to "You must use the updated version of the mobile app for web application login!",

        "adderr_sameeartag" to "Same eartag does exist!",

        "monthlist" to "January,February,March,April,May,June,July,August,September,October,November,December",
        "dayslist" to "Mon,Tu,We,Thu,Fri,Sat,Sun",

        "calendar_month" to "Month",
        "calendar_week" to "Week",

        "milk_morning" to "Morning",
        "milk_noon" to "Noon",
        "milk_evening" to "Evening",

        "addmilk_betweend_dates" to "The selected milking date must be within the dates when the animal is in MILK. To Add Milking Periods add dates of calving.",
        "addmilk_add" to "Add Milk",
        "addmilk_time" to "Milking Time: ",
        "addmilk_miktar" to "Quantity: ",

        "addnotif_add" to "Add Notif",
        "addnotif_date" to "Notification Date: ",
        "addnotif_baslik" to "Title",
        "addnotif_not" to "Notes",
        "addnotif_allusers" to "Notify all users",
        "notifbar_showtmm" to "Show Done",
        "notifbar_add" to "+ Add Remainder",


        "login_giris" to "Cow-Master Login",
        "login_eposta" to "E-mail address",
        "login_pass" to "Password",
        "login_seepass" to "Show password",
        "login_captext" to "Text in captcha",
        "login_gir" to "Login",
        "login_forgot" to "Forgot Password",
        "login_new" to "Register",
        "login_passsent" to "Password is sent",
        "login_senttext" to "Your password has been sent to your e-mail address, please check your e-mails.",
        "login_iste" to "Request Password",

        "login_capterr" to "You entered the text in the captcha incorrectly.",

        "renew_form" to "Change your Web Password",
        "renew_send" to "Change Password",
        "renew_pass1" to "New password",
        "renew_pass2" to "Repeat new password",
        "renew_change" to "Change Password",

        "adddurum_add" to "Add Event",
        "adddurum_date" to "Event Date:",
        "adddurum_ackl" to "Notes",
        "adddurum_select" to "Select Event",

        "adddurum_bogaadi" to "Sire name, tag",
        "adddurum_hastaadi" to "Diagnosed as",
        "adddurum_asiadi" to "Vaccine name",

        "adddurum_sagmaldur" to "Milking State ",
        "adddurum_sagmal" to "MILKING",
        "adddurum_kuru" to "DRY",

        "adddurum_tarti" to "Weight Result: ",

        "adddurum_gbtest" to "Pregnancy Test Result: ",
        "adddurum_pozitif" to "POSITIVE",
        "adddurum_negatif" to "NEGATIVE",

        "cowcalf_list" to "Calves",
        "cowcalf_notfound" to "No calf record found. Please enter dam's ear tag correctly for calves",
        "cowcalf_dtrh" to "Brth.Dt: ",
        "cowcalf_ctrh" to "Dispose.Dt: ",

        "detay_sec" to "Select Cattle",
        "detay_dismiss" to "Dispose",
        "detay_sil" to "Delete",
        "dismiss_sebep" to "Dispose Reason (Died, Sold, Culled etc.)",
        "dismiss_date" to "Date of Disposal",
        "dis_died" to "Died",
        "dis_sold"  to "Sold",
        "dis_culled" to "Culled",
        "dis_lost" to "Lost",
        "dis_other" to "Other",


        "detayevt_title" to "Event List",
        "detayevt_nodrm" to "No event record found.",

        "detay_title" to "General Info",
        "detay_edit" to "✎ Edit",
        "detay_kupe" to "Ear Tag",
        "detay_name" to "Name",
        "detay_gender" to "Gender",
        "detay_birth" to "Birth Date",

        "detay_irk" to "Breed",
        "detay_yas" to "Age",
        "detay_giris" to "How obtained",
        "detay_anne" to "Dam Ear tag",
        "detay_baba" to "Sire Ear tag, name",
        "detay_notes" to "Notes",

        "detaykilo_title" to "Weight Tracking",
        "detaykilo_birim" to " kg",
        "detaykilo_son2" to "Weight gain between the last 2 scales",
        "detaykilo_genel" to "Overall average daily weight gain",
        "detaykilo_enaz" to "For weight gain tracking, add at least 2 weight scales!",

        "detaymilk_title" to "Milk Yield",
        "detaymilk_add" to "+ Add Milk",

        "detaymilk_trh" to "Date",
        "detaymilk_mik" to "MilkQty",
        "detaymilk_gun" to "Daily",
        "detaymilk_sgg" to "DIM",
        "detaymilk_norecord" to "There is no milking period yet, it will be created when you add the dates of calving.",

        "detayntf_title" to "Notifications (60 days)",
        "detayntf_no" to "No upcoming notification found!",

        "detay_cowsil" to "Delete Cow",
        "detay_cowsil_not" to "All records, events, notifications of the cow will be deleted!",
        "detay_cowsil_sut" to "Also Delete individual milk records.",
        "detay_cowsil_full" to "Delete Completely",

        "leftpn_app" to "Herd Management App for Dairies",
        "nav_sum" to "Dairy",
        "nav_list" to "Cattle",
        "nav_milk" to "Milk",
        "nav_fin" to "Finance",

        "sumnum_inek" to "Cows",
        "sumnum_duve" to "Heifers",
        "sumnum_tosun" to "Bulls",
        "sumnum_dana" to "Weaners",
        "sumnum_buz" to "Calves",

        "sumdrm_hasta" to "Sick",
        "sumdrm_gebe" to "Pregnant",
        "sumdrm_sagmal" to "Milking",
        "sumdrm_kuru" to "Dry",
        "sumdrm_tohum" to "Inseminated",
        "sumdrm_taze" to "Fresh",
        "sumdrm_bos" to "Open",

        "sumbar_say" to "Number of Cattle:",
        "sumbar_profil" to "Profile",
        "sumbar_out" to "Log out",

        "sumfin_gelir" to "Total Income: ",
        "sumfin_gider" to "Total Expenses: ",
        "sumfin_kar" to "Net Income: ",


        "addherd_add" to "Add New Herd",
        "addherd_edit" to "Edit",
        "addherd_ad" to "Herd, Group Name",
        "addherd_renk" to "Select Color",
        "addherd_errgrup" to "↑ Add a name for the group!",
        "addherd_errrenk" to "↓ Select color for the group!",
        "addherd_addnow" to "Add",
        "addherd_kaydet" to "Save",

        "sumnum_row" to "No %s in Dairy!",

        "wean_text" to "Weaning NOTIFICATIONS for calves: ",
        "wean_days" to " Days ",

        "addlist_kupeerr" to "A record does exist with the same ear tag!",
        "addlist_success" to "%s records added successfully",
        "addlist_fail" to "\n%s records failed",
        "addlist_saved" to "%s new cattle added",
        "addlist_success" to "%s new cattle added",
        "addlist_add" to "Add Cattle",
        "addlist_new" to "+ Add New",
        "addlist_save" to "Save List",

        "addlist_kupe" to "Ear Tag",
        "addlist_ismi" to "Name",
        "addlist_dtrh" to "Birth Date",
        "addlist_irk" to "Breed",
        "addlist_anne" to "Dam Ear tag",
        "addlist_baba" to "Sire tag, name",

        "list_baradd" to "+Add Cattle",
        "list_barara" to "Search..",
        "list_haylist" to "Cattle List",
        "list_order" to "Order (%s)",
        "list_all" to "All",

        "newcow_kuperr" to "Enter Ear tag",
        "newcow_dogumerr" to "Enter Birth date",

        "durumDY" to "Gives Birth",
        "durumHG" to "Sick",
        "durumTY" to "Inseminated",
        "durumKG" to "Heat Period",
        "durumAY" to "Vaccinated",
        "durumSK" to "Weaned",
        "durumKA" to "Dry-Off",
        "durumGK" to "Preg Check",
        "durumGKp" to "Preg Check: Positive",
        "durumGKn" to "Preg Check: Negative",
        "durumKO" to "Weighed",
        "durumKOr" to "Weighed in at: %s Kg",
        "durumAB" to "Abortion",
        "durumDD" to "Other",


        "herdlist_title" to "Herd and Groups",
        "herdlist_sil" to "Herd Delete(%s)",
        "herdlist_siltext" to "All the cattle in the group will be removed from group, and the group name will be deleted ",
        "herdlist_add" to "+ Add New Herd, Group",


        "summilk_sutmik" to "Total Milked",
        "summilk_buzmik" to "For Calves",
        "summilk_kalan" to "Gross Milk",
        "summilk_cowsut" to "Cow Daily Avg",
        "summilk_dairy" to "Dairy Daily Avg",
        "summilk_cowsay" to "Daily Milked Cows",
        "summilk_gelir" to "Period Income",
        "summilk_dates" to "30-Day Milk (%s)",
        "summilk_sgg" to "Annual DIM: %s",
        "summilk_period_cowavg" to "Intra-period Cow yield avg",

        "sum_drmtitle" to "State Summary",
        "sum_haytitle" to "Number Of",
        "sum_disdeltitle" to "Disposed / Deleted",
        "sum_milktitle" to "Monthly Milk Summary",
        "sum_fintitle" to "Incomes-Expenses",

        "sum_notiftitle" to "Upcoming Notifications",
        "sum_evttitle" to "Latest Event Logs",
        "sum_distitle" to "Disposed Cattle (%s)",

        "single_basarili" to "New record added successfully",
        "single_eklendi" to "Cattle Saved",
        "single_title" to "Add New Cattle",
        "single_gender" to "Gender: ",
        "single_dtrh" to "Birth Date: ",
        "single_sebep" to "How obtained",
        "single_suru" to "Select Herd, Group: ",
        "single_addimg" to "Add Image",

        "finadd_gelir" to "Add Income",
        "finadd_gider" to "Add Expense",
        "finadd_date" to "Transaction Date: ",
        "finadd_paydate" to "Payment Date: ",
        "finadd_cat" to "Select Category",
        "finadd_not" to "Add Notes",
        "finadd_tutar" to "Amount: ",

        "FinPay_Pesin" to "Cash",
        "FinPay_Card" to "Credit Card",
        "FinPay_Vade" to "Deferred",
        "FinPay_Taksit" to "Installment",

        "finbar_gelir" to "Incomes",
        "finbar_gider" to "Expenses",
        "finbar_add" to "+ Add New",
        "finbar_dates" to " -Date Period- ",
        "finbar_dateerr" to "The start date must be earlier than end date!",
        "finbar_total" to " Total:  ",

        "fin_yetki" to "You currently do not have financial privilages, please ask privilages for financial management module from your Dairy Administrator.",

        "diss_ozettitle" to "Disposed Summary",

        "diss_title" to "Disposed Cattle: ",
        "diss_liste" to "Disposed List",
        "diss_toplam" to "Total",
        "diss_dty_title" to "Details",
        "diss_cikyasi" to "Age When Disposed",
        "diss_ciktrh" to "Date of Disposal",
        "diss_ciksebep" to "Reason for Disposal",
        "diss_ciknotes" to "Notes",
        "diss_ciksil" to "Delete Disposed",
        "diss_back_not" to "The selected animal will be returned to the farm. Check the state records (insemination, estrus, etc.).",
        "diss_back_to" to "Back to Dairy",
        "diss_back_to1" to "Get Back to Dairy",
        "diss_year_limit" to "You can view the records for the current year and the past 4 years.",

        //CowDismiss-sebep
        "dis_died" to "Died",
        "dis_sold" to "Sold",
        "dis_culled" to "Culled",
        "dis_lost" to "Lost",
        "dis_other" to "Other",

        "evt_list_title" to "Event Logs",
        "evt_list_add" to "+ Add Event",
        "evt.selectall" to "All Events",
        "evt_gk_result" to "Result",

        "evt_bulk_tumu" to "All",
        "evt_bulk_male" to "Males",
        "evt_bulk_female" to "Females",
        "evt_bulk_cows" to "Cows",
        "evt_bulk_calf" to "Calves",
        "evt_bulk_preg" to "Pregnant",
        "evt_bulk_milk" to "In Milk",
        "evt_bulk_dry" to "Dry",
        "evt_bulk_fresh" to "Fresh",
        "evt_bulk_open" to "Open",

        "evt_bulk_notselect" to "No cow selected yet!",
        "evt_bulk_select" to "(Select cattle)",
        "evt_bulk_kupe" to "Eartag",
        "evt_bulk_isim" to "Name",


        "iconstat_title" to "Cattle State",
        "iconstat_fresh_title" to "Fresh Cow",
        "iconstat_abort_title" to "Aborted",
        "iconstat_notgebe_title" to "Negative Pregnancy Test",
        "iconstat_hasta_title" to "Sickness Detected",
        "iconstat_hasta_text" to "Follow the treatment process",
        "iconstat_kizgin_title" to "Heat Detected",
        "iconstat_gebe_title" to "Pregnancy Information",
        "iconstat_kuru_title" to "Dry Period",
        "iconstat_asi_title" to "Vaccinated",
        "iconstat_sutkes_title" to "Weaned",
        "iconstat_tarti_title" to "Weighing Result: ",
        "iconstat_sagmal_title" to "Milking Cow",
        "iconstat_sutbuz_title" to "Milk Calf",

        "detay_toast_sonra" to "%s day(s) later",
        "detay_toast_bugun" to "Today",
        "detay_toast_gecti" to "%s day(s) overdue",
        "detay_toast_tohumtrh" to "Insemination Date: %s",
        "detay_toast_gebesure" to "Gestation period: %s day(s)",
        "detay_toast_dogurmatrh" to "Calving due date: %s",
        "detay_toast_kizginlikvar" to "She has been in heat lately, observe for any sign of heat cycle",
        "detay_toast_gebetitle" to "Gestation Details:",
        "detay_toast_sagmaltitle" to "Lactation period: %s.day",
        "detay_toast_sagmal_sonu" to "Dry period start: ",
        "detay_toast_days_later" to "day(s) later",
        "detay_toast_sagmal_geciyor" to "The optimal milking period is 310 days, Inseminate during heat period",
        "detay_toast_sagmalhayvan" to "Milking Cow:",

        //Bildir title ve text
        "hatirekle_title" to "Reminding",
        "durekle_bildirim_kizgin" to "Watch for signs of estrus",
        "durekle_bildirim_kizgintext" to "She was seen in heat on %s, observe heat signs!",
        "durekle_bildirim_tohum" to "Check Insemination Success",
        "durekle_bildirim_tohumtext" to "She was inseminated on %s, watch the cow if there are signs of heat period!",
        "durekle_bildirim_gebekontrol" to "Pregnancy Check",
        "durekle_bildirim_gebetext" to "She was inseminated on %s, a preg check may verify pregnancy",
        "durekle_bildirim_rotacorona" to "Rotavirus-Coronavirus Vaccine",
        "durekle_bildirim_rotacoronatext" to "Apply Bovine Rotavirus-Coronavirus Vaccine 3-6 weeks before calving!",
        "durekle_bildirim_kuru" to "DRY Period",
        "durekle_bildirim_kurutext" to "Calving date is upcoming, adjust feeding pattern in accordance with DRY period!",
        "durekle_bildirim_dogum" to "Calving is soon",
        "durekle_bildirim_dogumtext" to "End of gestation, observe the signs of giving birth!",
        "durekle_bildirim_kurubitis" to "End of DRY",
        "durekle_bildirim_kurubitistext" to "Calving date is upcoming, change feeding pattern for EARLY lactation period gradually!",
        "calf_weaning_title" to "Weaning Time",
        "calf_weaning_text" to "It's time for the planned weaning.",


        "genfunc_bugun_dogum" to "Gives birth today",
        "genfunc_dun_dogum" to "Calved yesterday",
        "genfunc_dogum_yapti" to "Calved %s days ago",
        "genfunc_no_heat" to "%s days After Calving, no heat period signs seen, observe carefully",
        "genfunc_tohumlama_yap" to "For calf productivity, inseminate within the first 100 days after calving (%s days)",
        "genfunc_bugun_dusuk" to "Having an abortion",
        "genfunc_dusuk_yapti" to "Had an abortion %s days ago",
        "genfunc_tohumlama_tutmadi" to "Insemination failure, repeat insemination when heat signs observed",
        "genfunc_hastalik_bugun" to "Not well today, get veterinary support for appropriate treatment",
        "genfunc_hastalik_goruldu" to "was sick %s days ago, observe recuperation",
        "genfunc_kizginlik_gor" to "She was in heat %s days ago, observe any sign of heat recurrence!",
        "genfunc_bugun_tohum" to "Inseminated recently, expected calving date: %s",
        "genfunc_tohum_kizginlik" to "Inseminated %s days ago, observe any sign of heat period in case of conception failure",
        "genfunc_tohum_yapildi" to "Inseminated on %1s, %2s remaining days to estimated calving date",
        "genfunc_tohumall_yapildi" to "Remaining days to estimated calving date: %s days",
        "genfunc_dogum_yakin" to "(%2s.day) Calving due date: %1s days later",
        "genfunc_dogum_bugun" to "(%s.day) TODAY is calving due date",
        "genfunc_dogum_gecti" to "(%2s.day) Calving due date was %1s days ago",
        "genfunc_dogum_cokgecti" to "(%2s.day) Calving is %1s days overdue,  seek for VETERINARY support",
        "genfunc_gebelik_test" to "(%s.day) Make a Pregnancy Test to confirm pregnancy.",
        "genfunc_erken_besleme" to "(%s days left) Change feeding pattern for EARLY lactation period gradually!",
        "genfunc_kuru_besleme" to "(%s days left) Change feeding pattern for DRY Period gradually!",
        "genfunc_bugun_kuru" to "Dry period started",
        "genfunc_kuruya_alindi" to "in Dry period for %s days",
        "genfunc_bugun_asi" to "Vaccinated",
        "genfunc_asi_yapildi" to "Vaccinated %s days ago",
        "genfunc_bugun_sutkes" to "Weaned today",
        "genfunc_sutten_kesildi" to "Weaned %s days ago",
        "genfunc_kilo_sonucu" to "Last weighing result: %1s %2s",
        "genfunc_aylik_kilo" to "Check body weight and log the result monthly",
        "genfunc_septisemi_yap" to "Apply Septicemia Serum right after the birth!",
        "genfunc_kilo_ekle" to "Add birth body weight into the logs!",

        "milkview_adddairy" to "Add Dairy Milk",
        "milkview_miktarerr" to "Please enter milk quantity!",
        "milkview_sagmalerr" to "Please enter the number of milked cows!",

        "milkview_dairyyield" to "Dairy Milk Yield",
        "milkview_bardairy" to "Dairy Milk",
        "milkview_barbirey" to "Cow Milk",
        "milkview_dairylist" to "Dairy Milk List",
        "milkview_persagim" to "Milking",
        "milkview_daily" to "Daily",

        "milkview_bireylist" to "Cow Milk List",
        "milkview_sagmal" to "Milking Cow",

        "milkview_birey" to "Cow Milk Yield",
        "milkview_date" to "Date",
        "milkview_kupe" to "Ear Tag",
        "milkview_sagilan" to "Milked",
        "milkview_buz" to "Calves",
        "milkview_kalan" to "Remaining",
        "milkview_sagmalsay" to "Number of Milking Cows",
        "milkview_price" to "Milk Price (lt)",

        )

    private val trDict = mapOf(
        "shared_iptal" to "İptal",
        "shared_tmm" to "Tamam",
        "shared_sil" to "Sil",
        "shared_save" to "Kaydet",
        "shared_select" to "Seçiniz",
        "shared_kapat" to "Kapat",
        "shared_add" to "+ Ekle",
        "shared_male" to "Erkek",
        "shared_female" to "Dişi",
        "shared_herdselect" to "Sürü Seçiniz",
        "shared_addgrup" to "+Yeni Grup Ekle",
        "shared_edityetki" to "Düzenleme Yetkisi",
        "shared_yetkitext" to "Web uygulama ile değişiklik yapabilmek için Çoklu kullanıcı paketlerimizi tercih edebilirsiniz.\n" +
                "Yükseltme işlemini mobil uygulama Profilim menüsünden yapabilirsiniz.",
        //servis hata kodları
        "error_nouser" to "Kullanıcı epostası kayıtlı değil!",
        "error_password" to "Şifre hatalı!",
        "error_cowdb0" to "Web uygulama girişi için mobil uygulama güncel versiyonu kullanmalısınız!",

        "adderr_sameeartag" to "Aynı küpe nu. kullanılıyor!",

        "monthlist" to "Ocak,Şubat,Mart,Nisan,Mayıs,Haziran,Temmuz,Ağustos,Eylül,Ekim,Kasım,Aralık",
        "dayslist" to "Pzt,Sa,Çrş,Prş,Cu,Cts,Pz",
        "calendar_month" to "Ay",
        "calendar_week" to "Hafta",

        "milk_morning" to "Sabah",
        "milk_noon" to "Öğlen",
        "milk_evening" to "Akşam",


        "addmilk_betweend_dates" to "Seçilen sağım tarihi, hayvanın SAĞMAL olduğu tarihler içinde olmalı. Sağmal Dönem Eklemek için Doğum yaptığı tarihi ekleyiniz.",
        "addmilk_add" to "Süt Ekle",
        "addmilk_time" to "Sağım Zamanı: ",
        "addmilk_miktar" to "Sağılan Miktar: ",

        "addnotif_add" to "Bildirim Ekle",
        "addnotif_date" to "Bildirim Tarihi: ",
        "addnotif_baslik" to "Başlık",
        "addnotif_not" to "Notlar",
        "addnotif_allusers" to "Tüm kullanıcılar için hatırlat",
        "notifbar_showtmm" to "Tamamlanan Göster",
        "notifbar_add" to "+ Yeni Bildirim",

        "login_giris" to "Cow-Master Giriş",
        "login_eposta" to "E-posta adresiniz",
        "login_pass" to "Password",
        "login_seepass" to "Şifreyi Göster",
        "login_captext" to "Resimdeki yazı",
        "login_gir" to "Giriş Yap",
        "login_forgot" to "Şifremi Unuttum",
        "login_new" to "Yeni Hesap Oluştur",
        "login_passsent" to "Şifre Gönderildi",
        "login_senttext" to "Şifreniz eposta adresinize ve mobil cihazınıza bildirim olarak gönderildi, maillerinizi kontrol ediniz",
        "login_iste" to "Şifre İste",

        "login_capterr" to "Resimdeki yazıyı eksik girdiniz",

        "renew_form" to "Web giriş şifrenizi yenileyiniz",
        "renew_send" to "Şifre Değiştir",
        "renew_pass1" to "Yeni şifreniz",
        "renew_pass2" to "Yeni şifreniz tekrar",
        "renew_change" to "Şifre Değiştir",


        "adddurum_add" to "Durum Ekle",
        "adddurum_date" to "Olay Tarihi:",
        "adddurum_ackl" to "Açıklamalar",
        "adddurum_select" to "Durum Seçiniz!",

        "adddurum_bogaadi" to "Boğa Adı ve küpe",
        "adddurum_hastaadi" to "Hastalık adı",
        "adddurum_asiadi" to "Yapılan aşı adı",

        "adddurum_sagmaldur" to "Sağmal Durumu: ",
        "adddurum_sagmal" to "SAĞMAL",
        "adddurum_kuru" to "KURU",

        "adddurum_tarti" to "Tartı Sonucu: ",

        "adddurum_gbtest" to "Gebelik Test Sonucu: ",
        "adddurum_pozitif" to "POZİTİF",
        "adddurum_negatif" to "NEGATİF",

        "cowcalf_list" to "Buzağıları",
        "cowcalf_notfound" to "Buzağı kaydı bulunamadı, Buzağılarda Anne küpe numarasını bilgisini doğru giriniz!",
        "cowcalf_dtrh" to "D.Trh: ",
        "cowcalf_ctrh" to "Çık.Trh: ",

        "detay_sec" to "Hayvan Seçiniz",
        "detay_dismiss" to "Sürüden Çıkar",
        "detay_sil" to "Sil",
        "dismiss_sebep" to "Çıkarma Sebebi (ölüm, satış, kesim vs)",
        "dismiss_date" to "Sürüden Çıkarma Tarihi",
        "dis_died" to "Ölüm",
        "dis_sold"  to "Satış",
        "dis_culled" to "Kesim",
        "dis_lost" to "Kayıp",
        "dis_other" to "Diğer",

        "detayevt_title" to "Durum Bilgileri",
        "detayevt_nodrm" to "Henüz durum kaydı bulunmuyor.",

        "detay_title" to "Genel Bilgileri",
        "detay_edit" to "✎ Düzenle",
        "detay_kupe" to "Küpe Nu",
        "detay_name" to "İsmi",
        "detay_gender" to "Cinsiyeti",
        "detay_birth" to "Doğum Tarihi",

        "detay_irk" to "Irkı",
        "detay_yas" to "Yaşı",
        "detay_giris" to "Sürüye Girişi",
        "detay_anne" to "Anne Küpe",
        "detay_baba" to "Baba Küpe, Ad",
        "detay_notes" to "Notlar",

        "detaykilo_title" to "Kilo Takibi",
        "detaykilo_birim" to " kg",
        "detaykilo_son2" to "Son 2 tartı arasında kilo artışı",
        "detaykilo_genel" to "Genel ortalama günlük kilo artışı",
        "detaykilo_enaz" to "Kilo artış takibi için en az 2 kilo tartı durumu ekleyiniz!",

        "detaymilk_title" to "Süt Verimi",
        "detaymilk_add" to "+ Süt Ekle",

        "detaymilk_trh" to "Tarih",
        "detaymilk_mik" to "SütMik",
        "detaymilk_gun" to "Günlük",
        "detaymilk_sgg" to "SGG",
        "detaymilk_norecord" to "Henüz Süt Dönem kaydı bulunmuyor, Doğum yaptığı tarihleri eklediğinizde oluşacaktır.",

        "detayntf_title" to "Bildirimler (60 gün)",
        "detayntf_no" to "Yaklaşan Bildirim bulunamadı!",

        "detay_cowsil" to "Hayvan Sil",
        "detay_cowsil_not" to "Hayvanıza ait tüm kayıtlar silinecek, Onaylıyor musunuz?",
        "detay_cowsil_sut" to "Tüm bireysel süt kayıtlarını da sil.",
        "detay_cowsil_full" to "Tamamen Sil",

        "leftpn_app" to "Büyükbaş Hayvancılık Sürü Yönetim Sistemi",
        "nav_sum" to "Sürü Özeti",
        "nav_list" to "Hayvanlarım",
        "nav_milk" to "Süt Yönetimi",
        "nav_fin" to "Gelir-Gider",

        "sumnum_inek" to "İnek",
        "sumnum_duve" to "Düve",
        "sumnum_tosun" to "Tosun",
        "sumnum_dana" to "Dana",
        "sumnum_buz" to "Buzağı",

        "sumdrm_hasta" to "Hasta",
        "sumdrm_gebe" to "Gebe",
        "sumdrm_sagmal" to "Sağmal",
        "sumdrm_kuru" to "Kuruda",
        "sumdrm_tohum" to "Tohumlu",
        "sumdrm_taze" to "Taze",
        "sumdrm_bos" to "Boşta",

        "sumbar_say" to "Hayvan Sayısı:",
        "sumbar_profil" to "Profilim",
        "sumbar_out" to "Çıkış Yap",

        "sumfin_gelir" to "Gelirler: ",
        "sumfin_gider" to "Giderler: ",
        "sumfin_kar" to "Kar: ",


        "addherd_add" to "Sürü Ekle",
        "addherd_edit" to "Düzenle",
        "addherd_ad" to "Sürü, Grup Adı",
        "addherd_renk" to "Renk Seçiniz",
        "addherd_errgrup" to "↑ Sürü, Grup ismi ekleyiniz!",
        "addherd_errrenk" to "↓ Sürü, Grup renk seçiniz!",
        "addherd_addnow" to "Ekle",
        "addherd_kaydet" to "Kaydet",
        "sumnum_row" to "İşletmenizde %s mevcut değildir!",

        "wean_text" to "Buzağılar için sütten kesme BİLDİRİMİ: ",
        "wean_days" to " Gün ",

        "addlist_kupeerr" to "Aynı Küpe Nu. ile kayıtlı hayvan listede mevcut!",
        "addlist_success" to "%s kayıt başarıyla eklendi.",
        "addlist_fail" to "\n%s kayıt hatalı",
        "addlist_saved" to "%s Hayvan Eklendi",
        "addlist_success" to "%s kayıt başarıyla eklendi.",
        "addlist_add" to "Hayvan Ekle",
        "addlist_new" to "+ Yeni Ekle",
        "addlist_save" to "Listeyi Kaydet",

        "addlist_kupe" to "Küpe Nu.",
        "addlist_ismi" to "İsmi",
        "addlist_dtrh" to "Doğum Tarihi",
        "addlist_irk" to "Irkı",
        "addlist_anne" to "Anne Küpe",
        "addlist_baba" to "Baba küpe, ad",

        "list_baradd" to "+Hayvan Ekle",
        "list_barara" to "Ara...",
        "list_haylist" to "Hayvanlarım",
        "list_order" to "Sırala (%s)",
        "list_all" to "Tümü",

        "newcow_kuperr" to "Küpe numarasını giriniz",
        "newcow_dogumerr" to "Doğum tarihini giriniz",

        "durumDY" to "Doğum Yaptı",
        "durumHG" to "Hastalık Görüldü",
        "durumTY" to "Tohumlama Yapıldı",
        "durumKG" to "Kızgınlık Görüldü",
        "durumAY" to "Aşı Yapıldı",
        "durumSK" to "Sütten Kesildi",
        "durumKA" to "Kuruya Alındı",
        "durumGK" to " Gebelik Kontrolü",
        "durumGKp" to " Gebelik Kontrolü: Pozitif",
        "durumGKn" to " Gebelik Kontrolü: Negatif",
        "durumKO" to "Kilo Ölçümü",
        "durumKOr" to "Tartı Sonucu: %s Kg",
        "durumAB" to "Düşük Yaptı",
        "durumDD" to "Diğer",

        "herdlist_title" to "Sürü, Padok Grupları",
        "herdlist_sil" to "Sürü Sil (%s)",
        "herdlist_siltext" to "Gruptaki hayvanlar Gruptan çıkarılacak ve Grup silinecek.",
        "herdlist_add" to "+ Yeni Sürü, Grup Ekle",


        "summilk_sutmik" to "Sağılan Süt",
        "summilk_buzmik" to "Buzağılar İçin",
        "summilk_kalan" to "Kalan Süt",
        "summilk_cowsut" to "Sağmal Günlük Verim",
        "summilk_dairy" to "İşletme Günlük Verim",
        "summilk_cowsay" to "Günlük Sağıma Giren",
        "summilk_gelir" to "Dönem Geliri",
        "summilk_dates" to "30 Günlük (%s)",
        "summilk_sgg" to "Yıllık SGG: %s",
        "summilk_period_cowavg" to "Dönem İçinde Sağmal Verim Ort.",


        "sum_drmtitle" to "Durum Özeti",
        "sum_haytitle" to "Hayvan Sayıları",
        "sum_disdeltitle" to "Çıkarılan / Silinen",
        "sum_milktitle" to "Aylık Süt Özeti",
        "sum_fintitle" to "Gelir-Gider",

        "sum_notiftitle" to "Haftalık Bildirimler",
        "sum_evttitle" to "Güncel Durum Kayıtları",
        "sum_distitle" to "Çıkarılan Hayvanlar (%s)",

        "single_basarili" to "Kayıt başarıyla eklendi.",
        "single_eklendi" to "Hayvan Eklendi",
        "single_title" to "Hayvan Ekle",
        "single_gender" to "Cinsiyeti: ",
        "single_dtrh" to "Doğum Tarihi: ",
        "single_sebep" to "İşletmeye nasıl girdi",
        "single_suru" to "Sürü, Padok Seçiniz: ",
        "single_addimg" to "Resim Ekle",

        "finadd_gelir" to "Gelir Ekle",
        "finadd_gider" to "Gider Ekle",
        "finadd_date" to "İşlem Tarihi: ",
        "finadd_paydate" to "Ödeme Tarihi: ",
        "finadd_cat" to "Kategori Giriniz",
        "finadd_not" to "Not Ekleyiniz",
        "finadd_tutar" to "Tutar: ",

        "FinPay_Pesin" to "Peşin",
        "FinPay_Card" to "K.Kartı",
        "FinPay_Vade" to "Vadeli",
        "FinPay_Taksit" to "Taksitli",

        "finbar_gelir" to "Gelirler",
        "finbar_gider" to "Giderler",
        "finbar_add" to "+ Yeni Ekle",
        "finbar_dates" to " -Tarih aralığı- ",
        "finbar_dateerr" to "Başlangıç tarihi daha önceki bir tarih seçilmeli!",
        "finbar_total" to " Toplam:  ",

        "fin_yetki" to "Gelir-Gider modulü yetkiniz mevcut değildir, İşletme yöneticinizden erişim yetkisi isteyebilirsiniz.",

        "diss_ozettitle" to "Çıkan Özet",
        "diss_title" to "Çikan Hayvanlar: ",
        "diss_liste" to "Çıkan Liste",
        "diss_toplam" to "Toplam Çıkan",
        "diss_dty_title" to "Çıkan Detay",
        "diss_cikyasi" to "Cıkarıldığında Yaşı",
        "diss_ciktrh" to "Çıkarılma Tarihi",
        "diss_ciksebep" to "Çıkarılma Sebebi",
        "diss_ciknotes" to "Notlar",
        "diss_ciksil" to "Çıkan Sil",
        "diss_back_not" to "Seçilen hayva işletmeye geri katılacak, Durum kayıtlarını (tohumlama, kızgınlık vs) kontrol ediniz.",
        "diss_back_to" to "İşletmeye Al",
        "diss_back_to1" to "İşletmeye Geri Al",
        "diss_year_limit" to "Bulunduğunuz yıl ve geçmiş 4 yıl kayıtları görülebilmektedir.",

        //CowDismiss-sebep
        "dis_died" to "Ölüm",
        "dis_sold" to "Satış",
        "dis_culled" to "Kesim",
        "dis_lost" to "Kayıp",
        "dis_other" to "Diğer",

        "evt_list_title" to "Durum Kayıtları",
        "evt_list_add" to "+ Durum Ekle",
        "evt.selectall" to "Tüm Durumlar",
        "evt_gk_result" to "Test Sonucu",

        "evt_bulk_tumu" to "Tümü",
        "evt_bulk_male" to "Erkekler",
        "evt_bulk_female" to "Dişiler",
        "evt_bulk_cows" to "İnekler",
        "evt_bulk_calf" to "Buzağılar",
        "evt_bulk_preg" to "Gebeler",
        "evt_bulk_milk" to "Sağmallar",
        "evt_bulk_dry" to "Kurular",
        "evt_bulk_fresh" to "Tazeler",
        "evt_bulk_open" to "Boşlar",

        "evt_bulk_notselect" to "Henüz hayvan seçmediniz!",
        "evt_bulk_select" to "(Hayvan seçiniz)",
        "evt_bulk_kupe" to "Küpe",
        "evt_bulk_isim" to "İsim",

        "iconstat_title" to "Durum Bilgisi",
        "iconstat_fresh_title" to "Yeni Doğum Yaptı",
        "iconstat_abort_title" to "Düşük Yaptı",
        "iconstat_notgebe_title" to "Negatif Gebelik Testi",
        "iconstat_hasta_title" to "Hastalık Görüldü",
        "iconstat_hasta_text" to "Tedavi sürecini takip ediniz",
        "iconstat_kizgin_title" to "Kızgınlık Görüldü",
        "iconstat_gebe_title" to "Gebelik Bilgi",
        "iconstat_kuru_title" to "Kuruda",
        "iconstat_asi_title" to "Aşı Yapıldı",
        "iconstat_sutkes_title" to "Sütten Kesildi",
        "iconstat_tarti_title" to "Tartı Sonucu: ",
        "iconstat_sagmal_title" to "Sağmal İnek",
        "iconstat_sutbuz_title" to "Süt Buzağısı",

        "detay_toast_sonra" to "%s gün kaldı",
        "detay_toast_bugun" to "Bugün",
        "detay_toast_gecti" to "%s gün geçti",
        "detay_toast_tohumtrh" to "Tohumlama tarihi: %s",
        "detay_toast_gebesure" to "Gebelik süresi: %s.gün",
        "detay_toast_dogurmatrh" to "Doğum zamanı: %s",
        "detay_toast_kizginlikvar" to "Son günlerde kızgınlık belirtisi gözlendi, kontrol ediniz!!!",
        "detay_toast_gebetitle" to "Gebelik Bilgi:",
        "detay_toast_sagmaltitle" to "Sağmal Dönem: <b>%s</b>.gun",
        "detay_toast_sagmal_sonu" to "Kuru dönem başlangıcı: ",
        "detay_toast_days_later" to "gün sonra",
        "detay_toast_sagmal_geciyor" to "Arzu edilen sağmal dönem süresi 310 gündür, kızgınlık zamanı TOHUMLAMA yapmalısınız!!!",
        "detay_toast_sagmalhayvan" to "Sağmal İnek:",

        //Bildirim ttitle ve text
        "hatirekle_title" to "Hatırlatma",
        "durekle_bildirim_kizgin" to "Kızgınlık Kontrolü",
        "durekle_bildirim_kizgintext" to " %s tarihinde kızgınlık görüldü, tekrar gözlemleyiniz!",
        "durekle_bildirim_tohum" to "Tohumlama Kontrolü",
        "durekle_bildirim_tohumtext" to " %s tarihinde tohumlama yapıldı, kızgınlık belirtilerini kontrol ediniz!",
        "durekle_bildirim_gebekontrol" to "Gebelik Kontrolü",
        "durekle_bildirim_gebetext" to " %s tarihinde tohumlama yapıldı, gebelik kontrolü yapılabilir",
        "durekle_bildirim_rotacorona" to "Rota-Corona Aşısı",
        "durekle_bildirim_rotacoronatext" to "Doğumdan 3-6 hafta önce Rotavirus - Coronavirus aşısını yapınız!",
        "durekle_bildirim_kuru" to "KURU Dönem",
        "durekle_bildirim_kurutext" to "Doğum yaklaşıyor, KURU dönem beslemesine başlayınız!",
        "durekle_bildirim_dogum" to "Doğum Yaklaştı",
        "durekle_bildirim_dogumtext" to "Gebelik sonuna gelindi, doğum belirtilerini gözleyiniz!",
        "durekle_bildirim_kurubitis" to "KURU ÇIKIŞ",
        "durekle_bildirim_kurubitistext" to "Doğum yaklaşmakta, Aşamalı olarak ERKEN dönem beslemesine geçiniz!",
        "calf_weaning_title" to "Sütten Kes",
        "calf_weaning_text" to "Planlanan sütten kesme zamanı geldi.",


        "genfunc_bugun_dogum" to "Bugün doğum yaptı",
        "genfunc_dun_dogum" to "Dün doğum yaptı",
        "genfunc_dogum_yapti" to "%s gün önce doğum yaptı",
        "genfunc_no_heat" to "Doğum sonrası %s.gün, henüz kızgınlık görülmedi",
        "genfunc_tohumlama_yap" to "Buzağı verimliliği için doğum sonrası ilk 100 gün içinde tohumlama yapınız (%s.gün)",
        "genfunc_bugun_dusuk" to "Düşük yaptı",
        "genfunc_dusuk_yapti" to "%s gün önce düşük yaptı",
        "genfunc_tohumlama_tutmadi" to "Başarısız tohumlama, Kızgınlıkta tekrar tohumlama yapınız",
        "genfunc_hastalik_bugun" to "Bugün hastalık görüldü.",
        "genfunc_hastalik_goruldu" to "%s gün önce hastalık görüldü.",
        "genfunc_kizginlik_gor" to "%s gün önce kızgınlık gözlendi, tekrar gözlemleyiniz",
        "genfunc_bugun_tohum" to "Tohumlama yapıldı, tahmini buzağılama tarihi: %s",
        "genfunc_tohum_kizginlik" to "%s gün önce Tohumlama yapıldı, tohumlama başarısı için kızgınlık belirtilerini kontrol ediniz",
        "genfunc_tohum_yapildi" to "%1s tarihinde Tohumlama yapıldı, tahmini buzağılama tarihine %2s gün kaldı",
        "genfunc_tohumall_yapildi" to "Doğuma kalan tahmini süre: %s gün",
        "genfunc_dogum_yakin" to "(%2s.gün) Doğum yaklaşıyor: %1s gün kaldı",
        "genfunc_dogum_bugun" to "(%s.gün) Hesaplanan doğum zamanı: BUGÜN",
        "genfunc_dogum_gecti" to "(%2s.gün) Hesaplanan doğum zamanı %1s gün geçti",
        "genfunc_dogum_cokgecti" to "(%2s.gün) Hesaplanan doğum zamanı %1s gün geçti,  VETERİNERinizle görüşünüz!!",
        "genfunc_gebelik_test" to "(%s.gün) Gebelik kontrolü yapılabilir.",
        "genfunc_erken_besleme" to "(%s gün kaldı) Aşamalı olarak ERKEN dönem beslemesine geçiniz.",
        "genfunc_kuru_besleme" to "(%s gün kaldı) Aşamalı olarak KURU dönem beslemesine geçiniz.",
        "genfunc_bugun_kuru" to "Kuruya alındı",
        "genfunc_kuruya_alindi" to "%s gün önce kuruya alındı",
        "genfunc_bugun_asi" to "Aşı yapıldı",
        "genfunc_asi_yapildi" to "%s gün önce aşı yapıldı",
        "genfunc_bugun_sutkes" to "Sütten kesildi",
        "genfunc_sutten_kesildi" to "%s gün önce sütten kesildi",
        "genfunc_kilo_sonucu" to "Son tartı sonucu: %1s %2s",
        "genfunc_aylik_kilo" to "Aylık canlı ağırlık ölçümü yapınız",
        "genfunc_septisemi_yap" to "Doğum sonrası SEPTİSEMİ serumu uygulayınız!",
        "genfunc_kilo_ekle" to "Doğum ağırlık bilgisini durum bilgilerine ekleyiniz!",


        "milkview_adddairy" to "Add Dairy Milk",
        "milkview_miktarerr" to "Süt miktarını ekleyiniz!",
        "milkview_sagmalerr" to "Sağılan hayvan sayısını ekleyiniz!",

        "milkview_dairyyield" to "İşletme Süt Verimi",
        "milkview_bardairy" to "Toplu Süt",
        "milkview_barbirey" to "Birey Süt",
        "milkview_dairylist" to "İşletme Süt Listesi",
        "milkview_persagim" to "Sağım",
        "milkview_daily" to "Günlük",

        "milkview_bireylist" to "Birey Süt Listesi",
        "milkview_sagmal" to "Sağmal",

        "milkview_birey" to "Birey Süt Verimi",
        "milkview_date" to "Tarih",
        "milkview_kupe" to "Küpe",
        "milkview_sagilan" to "Sağılan",
        "milkview_buz" to "Buzağılar",
        "milkview_kalan" to "Kalan",
        "milkview_sagmalsay" to "Sağmal Sayısı",
        "milkview_price" to "Süt Fiyatı (lt)",

        )

}

fun String.tr(): String {
    return Trans.getString(this)
}

fun String.Sprintf(vararg args: Any): String {
    var currentIndex = 0
    return this.replace(Regex("%(\\d+)?s")) { matchResult ->
        val indexGroup = matchResult.groups[1]
        val index = indexGroup?.value?.toIntOrNull()
        val arg = if (index != null) {
            if (index - 1 < args.size) args[index - 1] else matchResult.value
        } else {
            if (currentIndex < args.size) args[currentIndex++] else matchResult.value
        }
        arg.toString()
    }
}


fun String.Sprintf11(vararg args: Any): String {
    var currentIndex = 0
    return this.replace(Regex("%s")) {
        if (currentIndex < args.size) {
            val arg = args[currentIndex]
            currentIndex++
            arg.toString()
        } else {
            "%s"
        }
    }
}