package com.kelimesoft.cowmaster.pages

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import com.kelimesoft.cowmaster.components.BottomNavigation
import com.kelimesoft.cowmaster.components.LeftPane
import com.kelimesoft.cowmaster.components.NoContentYet
import com.kelimesoft.cowmaster.components.PageBox
import com.kelimesoft.cowmaster.models.Screens
import com.kelimesoft.cowmaster.models.Values
import com.kelimesoft.cowmaster.pages.dismiss.DismissContent
import com.kelimesoft.cowmaster.styles.MainPageStyle
import com.kelimesoft.cowmaster.viewmodals.AppData
import com.kelimesoft.cowmaster.pages.Router
import com.kelimesoft.cowmaster.pages.closeDropDowns
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.css.PointerEvents
import com.varabyte.kobweb.compose.foundation.layout.*
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint

@Page("/cikan")
@Composable
fun DismissPage() {
    AppData.appViewModel.activeScreens = Screens.Dismiss
    Router()
}

@Composable
fun DismissList() {
    val breakpoint = rememberBreakpoint()
    val dismissVM = AppData.dismissVM
    LaunchedEffect(dismissVM.dismissYear){
        dismissVM.getDisposedCattle()
    }

    PageBox (modifier = Modifier.pointerEvents(PointerEvents.Auto)) {
        Column(
            modifier = MainPageStyle.toModifier()
                .overflow(Overflow.Hidden)
                .onClick {
                    closeDropDowns()
                },
            verticalArrangement = Arrangement.Top,
            horizontalAlignment = Alignment.CenterHorizontally)
        {
            Row(
                modifier = Modifier.fillMaxSize()
                    .overflow(Overflow.Hidden)
            ) {
                if (breakpoint >= Breakpoint.SM){
                    LeftPane(breakpoint)
                }
                DismissContent(dismissVM)

            }
            if (breakpoint < Breakpoint.SM){
                Box(Modifier.height(Values.bottomBarHeight)) {  }
                Spacer()
                BottomNavigation(breakpoint)
            }
        }
    }
}
