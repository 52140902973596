package com.kelimesoft.cowmaster.pages.cowlist

import androidx.compose.runtime.*
import androidx.compose.web.events.SyntheticDragEvent
import com.kelimesoft.cowmaster.components.*
import com.kelimesoft.cowmaster.models.*
import com.kelimesoft.cowmaster.network.ApiResponse
import com.kelimesoft.cowmaster.network.ErrorListResponse
import com.kelimesoft.cowmaster.network.RestApi
import com.kelimesoft.cowmaster.styles.CowInputStyle
import com.kelimesoft.cowmaster.styles.MainButtonStyle
import com.kelimesoft.cowmaster.styles.TextButtonStyle
import com.kelimesoft.cowmaster.utils.*
import com.kelimesoft.cowmaster.viewmodals.AppData
import com.kelimesoft.cowmaster.components.AlertBox
import com.kelimesoft.cowmaster.components.AlertInfo
import com.kelimesoft.cowmaster.models.CowDetay
import com.kelimesoft.cowmaster.models.Routes
import com.kelimesoft.cowmaster.network.LongResponse
import com.varabyte.kobweb.compose.css.*
import com.varabyte.kobweb.compose.foundation.layout.*
import com.varabyte.kobweb.compose.ui.*
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.style.toModifier
import kotlinx.browser.document
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import kotlinx.serialization.json.Json
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.*
import org.w3c.files.File
import org.w3c.files.FileReader
import org.w3c.files.get
import org.w3c.xhr.FormData
import kotlin.js.Date
import org.w3c.dom.Image as DomImage

@Composable
fun AddSingleCow(cowDetay: CowDetay? = null, dam: String? = null, onClose: () -> Unit) {
    val scope = rememberCoroutineScope()
    var errorText by remember {mutableStateOf("")}
    var newCow by remember { mutableStateOf(NewListCow()) }
    var selectedHerd by remember { mutableStateOf("-1") }
    //var imageBase64: String by remember { mutableStateOf("") }
    //var orginalImgLink: String by remember { mutableStateOf("") }
    var dogumTrh by remember { mutableStateOf("") }

    fun showCowInfo() {
        cowDetay?.let { cow ->
            (document.getElementById("cow-kupe") as HTMLInputElement).value = cow.kupe
            (document.getElementById("cow-dogum") as HTMLInputElement).value = cow.birth
            (document.getElementById("cow-name") as HTMLInputElement).value = cow.name
            (document.getElementById("cow-anne") as HTMLInputElement).value = cow.dam
            (document.getElementById("cow-baba") as HTMLInputElement).value = cow.sire
            (document.getElementById("cow-irki") as HTMLInputElement).value = cow.breed
            //(document.getElementById("cow-giris") as HTMLInputElement).value = cow.gsbp
            if (cow.gender == 1) {
                (document.getElementById("cow-gender-1") as HTMLInputElement).checked = true
            }
            /*
            if (cow.img.isNotEmpty()) {
                orginalImgLink = cow.img
            }
            */
            AppData.cowListVM.selectedCow?.herd?.id?.let { herdid ->
                if (herdid > 0) {
                    selectedHerd = herdid.toString()
                }
            }
            newCow = NewListCow(hid = cow.hid, kupe = cow.kupe, name = cow.name, gender = cow.gender,
                dogum = cow.birth, breed = cow.breed, dam = cow.dam, sire = cow.sire)
        }
    }

    LaunchedEffect(true) {
        AppData.summaryViewModel.getHerdList()
        if (cowDetay != null) {
            delay(150)
            showCowInfo()
        } else if (dam != null) {
            try {
                (document.getElementById("cow-anne") as HTMLInputElement).value = dam
            }catch (e: Exception){}
            newCow.dam = dam
        }
    }

    //var addNewHerd by remember { mutableStateOf(false) }
    var boxAlert: AlertInfo? by remember { mutableStateOf(null) }
    var showProgress by remember { mutableStateOf(false) }

    /*
    fun resizeImage(file: File) {
        val maxSize = 560

        val reader = FileReader()
        reader.onload = { readerEvent ->
            val image = DomImage()
            image.onload = {
                // Resize the image
                val canvas = document.createElement("canvas") as HTMLCanvasElement
                val context = canvas.getContext("2d") as? CanvasRenderingContext2D
                var width = image.width.toDouble()
                var height = image.height.toDouble()

                if (width > height) {
                    if (width > maxSize) {
                        height *= maxSize / width
                        width = maxSize.toDouble()
                    }
                } else {
                    if (height > maxSize) {
                        width *= maxSize / height
                        height = maxSize.toDouble()
                    }
                }

                canvas.width = width.toInt()
                canvas.height = height.toInt()
                context?.drawImage(image, 0.0, 0.0, width, height)
                val dataUrl = canvas.toDataURL("image/jpeg")
                val clearBase64 = dataUrl.replace(Regex("^data:.+;base64,"), "")
                imageBase64 = dataUrl
                //newCow.image = clearBase64
                // Now you can use the 'base64String' for further processing or display
                console.log("image-selected")
            }
            if (readerEvent.target != null) {
                image.src = readerEvent.target.asDynamic().result.toString()
            }
            //image.src = readerEvent.target.asDynamic().result as String
        }
        reader.readAsDataURL(file)

    }

    fun acceptFileDrop(dropEvt: SyntheticDragEvent) {
        dropEvt.dataTransfer?.items?.let { items ->
            for (i in 0 until items.length) {
                if (items[i]?.kind == "file") {
                    val file = items[i]?.getAsFile()
                    if (file?.type?.matches(Regex("^image/.*")) == true) {
                        scope.launch {
                            resizeImage(file)
                        }
                    }
                }
            }
        }
    }

    fun selectImage() {
        (document.getElementById("file-select") as HTMLInputElement).click()
    }
    */

    fun addNewCow() {
        if (!Funcs.checkForm("addcow-form")) {
            return
        }
        showProgress = true
        var saveErr = ""
        //val saveList = arrayListOf(newCow).toList()
        //val weaning = if (AppStorage.getBool(AppStorage.addWeaningNotif)) AppStorage.getInt(AppStorage.weaningDays) else 0
        scope.launch {
            RestApi.addNewCow(newCow)?.let { res ->
                Json.decodeFromString<LongResponse>(res).let { cres ->
                    saveErr = cres.error?.tr() ?: ""
                    if (cres.data != null) {
                        newCow.hid = cres.data
                        AppData.cowListVM.addNewCowToList(newCow)
                    }
                }
            }

            if (saveErr.isEmpty()) {
                val alertText = "single_basarili".tr()
                boxAlert = AlertInfo("single_eklendi".tr(), alertText, "", "shared_tmm".tr(), onClose = {
                    boxAlert = null
                }) {
                    boxAlert = null
                }
                AppData.summaryViewModel.resetRequestCowsAdded()
                //AppData.cowListVM.getCowList()
                if (dam != null){
                    AppData.cowListVM.cowDetailVM.getCowCalves(dam)
                    onClose()
                }else{
                    (document.getElementById("addcow-form") as HTMLFormElement).reset()
                    //imageBase64 = ""
                    newCow = NewListCow()
                }
            } else {
                errorText = saveErr
            }
            showProgress = false
        }

    }

    fun editCow() {
        if (!Funcs.checkForm("addcow-form")) {
            return
        }
        val old = cowDetay!!

        val editedCow = EditedCow(hid = old.hid)

        var updateState = false

        if (old.kupe != newCow.kupe) {
            editedCow.kupe = newCow.kupe
        }
        if (old.name != newCow.name) {
            editedCow.name = newCow.name
        }
        if (old.birth != newCow.dogum) {
            editedCow.birth = newCow.dogum
            updateState = true

        }
        if (old.dam != newCow.dam) {
            editedCow.dam = newCow.dam
        }
        if (old.sire != newCow.sire) {
            editedCow.sire = newCow.sire
        }
        if (old.gender != newCow.gender) {
            editedCow.gender = newCow.gender
            updateState = true
        }
        /*
        if (old.gsbp != newCow.giris) {
            form.append("sebep", newCow.giris)
        }*/

        if (old.breed != newCow.breed) {
            editedCow.breed = newCow.breed
        }
        if (old.notes != newCow.notes) {
            editedCow.notes = newCow.notes
        }
        /*
        if (AppData.cowListVM.selectedCow?.herd?.id != newCow.herd) {
            form.append("herd", newCow.herd.toString())
        }*/
        showProgress = true
        scope.launch {
            RestApi.editSingleCow(editedCow)?.let { res ->
                Json.decodeFromString<ApiResponse>(res).let { ares ->
                    if (ares.data != null) {
                        AppData.cowListVM.cowDetayUpdated(editedCow, updateState)
                        onClose()
                    }
                }
            }
        }
        showProgress = false
    }

    Column(
        modifier = Modifier.fillMaxWidth(98.percent)
            .padding(4.px)
            .backgroundColor(Colors.White)
            .borderRadius(topLeft = 16.px, topRight = 16.px, bottomLeft = 6.px, bottomRight = 6.px)
            .boxShadow(2.px, (-1).px, 2.px, color = AppColors.Gray.copyf(0.5f))
            .minHeight(400.px)
            .maxHeight(96.percent)
            .overflow(Overflow.Hidden)
            .maxWidth(540.px),
        verticalArrangement = Arrangement.Top,
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        DialogTitle("single_title".tr(), onClose)
        VDivider()
        Column(
            modifier = Modifier.fillMaxWidth()
                .padding(16.px)
                .overflow(overflowX = Overflow.Hidden, overflowY = Overflow.Auto),
            verticalArrangement = Arrangement.Top,
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            Form(
                attrs = Modifier.fillMaxWidth()
                    .id("addcow-form")
                    .toAttrs(),
            ) {
                TextField(id = "cow-kupe", "kupe", "addlist_kupe".tr(), true) { value ->
                    newCow.kupe = value
                    newCow.errorText = ""
                }
                TextField(id = "cow-name", "isim", "addlist_ismi".tr()) { value ->
                    newCow.name = value
                }
                Row(
                    modifier = Modifier.fillMaxWidth(),
                    verticalAlignment = Alignment.CenterVertically,
                    horizontalArrangement = Arrangement.SpaceEvenly
                ) {
                    Span(
                        attrs = Modifier
                            .padding(leftRight = 4.px)
                            .fillMaxWidth(60.percent)
                            .fontSize(14.px)
                            .color(AppColors.TextColor)
                            .toAttrs()
                    ) {
                        Text("single_gender".tr())
                    }

                    GenderField(newCow.hid, cowDetay != null) { value ->
                        if (cowDetay == null){
                            newCow.gender = value.toInt()
                        }

                    }
                }

                Column(modifier = Modifier.fillMaxWidth()
                    .margin(bottom = 10.px),
                    horizontalAlignment = Alignment.End) {
                    Row(
                        modifier = Modifier.fillMaxWidth(),
                        verticalAlignment = Alignment.CenterVertically,
                        horizontalArrangement = Arrangement.SpaceEvenly
                    ) {
                        Span(
                            attrs = Modifier
                                .padding(leftRight = 4.px)
                                .fillMaxWidth(60.percent)
                                .fontSize(14.px)
                                .color(AppColors.TextColor)
                                .toAttrs()
                        ) {
                            Text("single_dtrh".tr())
                        }
                        DateField("addlist_dtrh".tr()) { value ->
                            newCow.dogum = value
                            newCow.errorText = ""
                            dogumTrh = value
                        }
                    }
                    /*
                    if (cowDetay == null && (dogumTrh.isNotEmpty() && dateDiff(Date(), Date(dogumTrh),  "d") < 90)){
                        WeaningSetting()
                    }*/
                }

                TextField(id = "cow-irki", "irki", "detay_irk".tr()) { value ->
                    newCow.breed = value
                }
                TextField(id = "cow-anne", "anne", "detay_anne".tr(), readOnly = dam != null) { value ->
                    newCow.dam = value
                }

                TextField(id = "cow-baba", "baba", "detay_baba".tr()) { value ->
                    newCow.sire = value
                }

                TextField(id = "cow-notes", "notes", "addnotif_not".tr()) { value ->
                    newCow.notes = value
                }

                /*
                Row(
                    modifier = Modifier.fillMaxWidth(),
                    verticalAlignment = Alignment.CenterVertically,
                    horizontalArrangement = Arrangement.SpaceEvenly
                ) {
                    Span(
                        attrs = Modifier
                            .padding(leftRight = 4.px)
                            .fillMaxWidth(60.percent)
                            .fontSize(14.px)
                            .color(AppColors.TextColor)
                            .toAttrs()
                    ) {
                        Text("single_suru".tr())
                    }

                    HerdFieldSingle(selectedHerd, addNewHerd = {
                        addNewHerd = true
                    }) {
                        selectedHerd = it
                        newCow.herd = it.toLong()
                    }
                }
                 */

            }
        }

        Spacer()

        Row(
            modifier = Modifier
                .fillMaxWidth()
                .backgroundColor(Colors.Gray.copyf(alpha = 0.1f))
                .padding(leftRight = 6.px, topBottom = 4.px),
            verticalAlignment = Alignment.CenterVertically
        ) {
            Button(
                attrs = TextButtonStyle.toModifier()
                    .fontSize(16.px)
                    .color(AppColors.DarkRed)
                    .onClick {
                        it.preventDefault()
                        onClose()
                    }
                    .toAttrs()

            ) {
                Text("shared_iptal".tr())
            }
            Spacer()

            Button(attrs = MainButtonStyle.toModifier()
                .color(Colors.White)
                .fontSize(16.px)
                .minHeight(36.px)
                .onClick {
                    it.preventDefault()
                    if (cowDetay != null) {
                        editCow()
                    } else {
                        addNewCow()
                    }

                }
                .toAttrs()
            ) {
                Text("shared_save".tr())
            }
        }

    }


    if (errorText.isNotEmpty()) {
        ToastView(errorText, pos = ToastPos.Top, color = Colors.Red) {
            errorText = ""
        }
    }


    if (boxAlert != null) {
        DialogView {
            AlertBox(boxAlert!!)
        }
    }

    if (showProgress) {
        DialogView {
            Progress()
        }
    }


}


@Composable
private fun TextField(
    id: String, name: String, placeholder: String = "",
    required: Boolean = false, readOnly: Boolean = false, onChange: (String) -> Unit
) {
    Input(
        type = InputType.Text,
        attrs = CowInputStyle.toModifier()
            .id(id)
            .attrsModifier {
                if (name == "irki") {
                    attr("autocomplete", "on")
                }
                attr("name", name)
                attr("placeholder", placeholder)
                if (required) {
                    attr("required", "true")
                }
                if (readOnly) {
                    attr("readonly", "true")
                }

            }
            .margin(bottom = 10.px)
            .toAttrs()
            {
                onInput {
                    onChange(it.value)
                }
            }
    )

}

@Composable
private fun GenderField(hid: Long,  disable: Boolean = false, onChange: (String) -> Unit) {
    Row(
        modifier = CowInputStyle.toModifier()
            .fillMaxWidth()
            .margin(bottom = 10.px),
        verticalAlignment = Alignment.CenterVertically,
        horizontalArrangement = Arrangement.SpaceAround
    ) {
        Row(
            modifier = Modifier.fillMaxWidth(),
            verticalAlignment = Alignment.CenterVertically,
            horizontalArrangement = Arrangement.Center
        ) {
            Input(
                type = InputType.Text,
                attrs = Modifier
                    .id("cow-gender-0")
                    .size(17.px)
                    .margin(right = 4.px)
                    .attrsModifier {
                        attr("type", "radio")
                        attr("name", "gender$hid")
                        attr("value", "0")
                        attr("checked", "checked")
                        if (disable){
                            attr("disabled", "disabled")
                        }
                    }

                    .toAttrs()
                    {
                        onInput {
                            onChange(it.value)
                        }
                    }
            )
            Label(
                attrs = Modifier
                    .textAlign(TextAlign.Start)
                    .fontSize(15.px)
                    .margin(right = 10.px)
                    .toAttrs()
            ) {
                Text("shared_female".tr())
            }
        }

        Row(
            modifier = Modifier.fillMaxWidth(),
            verticalAlignment = Alignment.CenterVertically,
            horizontalArrangement = Arrangement.Center
        ) {
            Input(
                type = InputType.Text,
                attrs = Modifier
                    .id("cow-gender-1")
                    .size(17.px)
                    .margin(right = 4.px)
                    .attrsModifier {
                        attr("type", "radio")
                        attr("name", "gender$hid")
                        attr("value", "1")
                        if (disable){
                            attr("disabled", "disabled")
                        }
                    }
                    .toAttrs()
                    {
                        onInput {
                            onChange(it.value)
                        }
                    }
            )
            Label(
                attrs = Modifier
                    .textAlign(TextAlign.Start)
                    .fontSize(15.px)
                    .toAttrs()
            ) {
                Text("shared_male".tr())
            }
        }


    }
}

@Composable
private fun DateField(placeholder: String = "", required: Boolean = false, onChange: (String) -> Unit) {
    Input(
        type = InputType.Date,
        attrs = CowInputStyle.toModifier()
            .id("cow-dogum")
            .padding(7.px)
            .attrsModifier {
                //attr("type", "text")
                attr("placeholder", placeholder)
                attr("required", if (required) "true" else "false")
                attr("max", Date().dateToDbStr())

            }
            .toAttrs()
            {
                onInput {
                    onChange(it.value)
                    (document.getElementById("cow-dogum") as HTMLInputElement).selectionEnd

                }
            }
    )
}

@Composable
private fun HerdFieldSingle(herdId: String = "-1", addNewHerd: () -> Unit, onChange: (String) -> Unit) {
    val sumVm = AppData.summaryViewModel
    var selectColor: CSSColorValue by remember { mutableStateOf(Colors.Transparent) }
    fun changeSelect(id: String) {
        (document.getElementById("cow-herd") as HTMLSelectElement).value = id
        if (id == "-1") {
            selectColor = Colors.Transparent
        } else {
            sumVm.herdList?.find { it.id == id.toLong() }?.let {
                selectColor = AppColors.herdColorList[it.color - 1]
            }
        }
    }
    LaunchedEffect(herdId) {
        changeSelect(herdId)
    }
    /*
    LaunchedEffect(true) {
        delay(100)
        val select = document.getElementById(id) as HTMLSelectElement
        select.onchange = {
            if (select.value == "-2") {
                select.value = "-1"
                addNewHerd()
            } else {
                if (select.value == "-1") {
                    selectColor = Colors.Transparent
                } else {
                    sumVm.herdList.find { it.id == select.value.toLong() }?.let {
                        selectColor = AppColors.herdColorList[it.clr - 1]
                    }
                }
                onChange(select.value)
            }
        }
    }*/
    Row(
        modifier = CowInputStyle.toModifier().fillMaxWidth(),
        verticalAlignment = Alignment.CenterVertically,
        horizontalArrangement = Arrangement.End
    ) {
        CircleBox(
            modifier = Modifier
                .margin(right = 10.px)
                .size(18.px)
                .backgroundColor(selectColor)
        ) {}
        Select(
            attrs = Modifier
                .id("cow-herd")
                .padding(2.px)
                .border(0.px)
                .color(AppColors.TextColor)
                .fontSize(14.px)
                .attrsModifier {
                    attr("value", herdId)
                }
                .toAttrs {
                    onChange { slct ->
                        //this as HTMLSelectElement
                        if (slct.value == "-2") {
                            changeSelect("-1")
                            addNewHerd()
                        } else {
                            onChange(slct.value ?: "-1")
                            //changeSelect(slct.value ?: "-1")
                        }
                    }
                }


        ) {
            Option(
                "-1"
            ) {
                Text("shared_herdselect".tr())
            }

            sumVm.herdList?.forEach { herd ->
                Option(
                    herd.id.toString()
                ) {
                    Text(herd.name)
                }
            }

            Option(
                "-2"
            ) {
                Text("shared_addgrup".tr())
            }
        }
    }

}


/*

fun resizeImage(file: File, maxSize: Int): Promise<Pair<Blob, String>> {
    return Promise { resolve, reject ->
        // Ensure it's an image
        if (file.type.startsWith("image/")) {
            console.log("An image has been loaded")

            // Load the image

        } else {
            reject(Error("Not an image file"))
        }
    }
}

fun main() {
    val file: File = TODO() // Replace with your file
    val maxSize = 544 // TODO: Replace with your desired max size
    resizeImage(file, maxSize)
        .then { (resizedImage, dataUrl) ->
            // Handle the resized image and dataUrl here
            window.alert("Image resized successfully. URL: $dataUrl")
        }
        .catch { error ->
            // Handle any errors that occurred during the resizing process
            console.error("Error resizing image: ${error.message}")
        }
}

fun dataURLToBlob(dataURL: String): Blob {
    // Implement the conversion from dataURL to Blob here
    TODO()
}

 */

/*
fun ImageSelectBox(){
    Box(
        modifier = Modifier.fillMaxWidth(),
        contentAlignment = Alignment.Center
    )
    {

        Box(modifier = Modifier.fillMaxWidth()
            .backgroundColor(Colors.Gray.copyf(alpha = 0.4f))
            .margin(8.px)
            .styleModifier {
                if (imageBase64.isNotEmpty()) {
                    //property("pointer-events", "none")
                }
            }
            .cursor(Cursor.Pointer)
            .overflow(Overflow.Hidden)
            .border((0.5).px, LineStyle.Solid, color = Colors.Gray.copyf(alpha = 0.6f))
            .borderRadius(r = 10.px)
            .width(260.px)

            .onDrop { dropevt ->
                dropevt.preventDefault()
                if (imageBase64.isEmpty()) {
                    acceptFileDrop(dropevt)
                }
            }
            .onDragOver {
                it.preventDefault()
                //fileExist = true
            }
            .onClick {
                if (imageBase64 == "") {
                    selectImage()
                } else {
                    it.preventDefault()
                }
            }
            .height(190.px), contentAlignment = Alignment.Center)
        {
            Input(
                type = InputType.File,
                attrs = CowInputStyle.toModifier()
                    .id("file-select")
                    .display(DisplayStyle.None)
                    .attrsModifier {
                        attr("name", "file")
                        attr("placeholder", "Select Image")
                        attr("accept", "image/*")
                    }
                    .margin(bottom = 10.px)
                    .toAttrs()
                    {
                        onInput { evt ->
                            val file = evt.target.files?.get(0)
                            if (file?.type?.matches(Regex("^image/.*")) == true) {
                                scope.launch {
                                    resizeImage(file)
                                }
                            }
                        }
                    }
            )

            if (imageBase64.isNotEmpty() || orginalImgLink.isNotEmpty()) {
                val shareId = AppData.cowUser?.shareId
                Image(
                    modifier = Modifier
                        .fillMaxSize()
                        .borderRadius(r = 10.px)
                        .objectFit(ObjectFit.Cover),
                    src = imageBase64.ifEmpty { "${Routes.WebRoot}cowimages/$shareId/$orginalImgLink.jpg" }
                )
                Column(
                    modifier = Modifier.fillMaxSize()
                        .padding(6.px),
                    verticalArrangement = Arrangement.Bottom
                ) {
                    Row(
                        Modifier.fillMaxWidth(),
                    ) {
                        Spacer()
                        Button(attrs =
                        TextButtonStyle.toModifier()
                            .backgroundColor(Colors.White.copyf(alpha = 0.9f))

                            .onClick {
                                it.preventDefault()
                                scope.launch {
                                    delay(200)
                                    imageBase64 = ""
                                    //newCow.image = "-1"
                                    orginalImgLink = ""
                                }
                                it.stopPropagation()

                            }
                            .toAttrs()) {
                            Span(
                                attrs = Modifier
                                    .fontSize(16.px)
                                    .color(AppColors.Red)
                                    .toAttrs()
                            ) {
                                Text("shared_sil".tr())

                            }
                        }
                    }
                }
            } else {
                Span(
                    attrs = Modifier
                        .fontSize(20.px)
                        .color(AppColors.TextColor)
                        .padding(6.px)
                        .backgroundColor(Colors.White.copyf(alpha = 0.8f))
                        .borderRadius(r = 10.px)
                        .toAttrs()
                ) {
                    Text("single_addimg".tr())
                }
            }
        }

        */
    }
}

 */

