package com.kelimesoft.cowmaster.components

import androidx.compose.runtime.Composable
import com.kelimesoft.cowmaster.models.AppColors
import com.kelimesoft.cowmaster.styles.*
import com.kelimesoft.cowmaster.utils.Sprintf
import com.kelimesoft.cowmaster.utils.tr
import com.kelimesoft.cowmaster.viewmodals.AppData
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.css.TextDecorationLine
import com.varabyte.kobweb.compose.foundation.layout.*
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.components.style.toModifier
import org.jetbrains.compose.web.ExperimentalComposeWebApi
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text

@Composable
fun SummaryCard(title: String, content: @Composable ColumnScope.() -> Unit) {
    Column(
        modifier = StaggeredItemStyle.toModifier()
            .then(SummaryCardstyle.toModifier())
        //.maxWidth(420.px)
        ,
        verticalArrangement = Arrangement.Top,
        horizontalAlignment = Alignment.CenterHorizontally

    ) {

        P(
            attrs = Modifier
                .id("title")
                .fillMaxWidth(90.percent)
                .textAlign(textAlign = TextAlign.Center)
                .margin(10.px)
                .padding(topBottom = 4.px, leftRight = 8.px)
                .fontSize(16.px)
                .fontWeight(FontWeight.Bold)
                .color(AppColors.TextColor)
                .backgroundColor(AppColors.GrayDark.copyf(alpha = 0.3f))
                .borderRadius(r = 10.px)
                .boxShadow(1.px, 2.px, blurRadius = 4.px, color = Colors.Black.copyf(alpha = 0.3f))
                .toAttrs()
        ) {
            Text(title)
        }
        content()
    }

}


@OptIn(ExperimentalComposeWebApi::class)
@Composable
fun NumberCard(title: String, icon: String, say: Int, color: CSSColorValue) {
    Column(
        modifier = NumCardStyle.toModifier()

            .backgroundColor(color),
        verticalArrangement = Arrangement.SpaceAround,
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        Image(
            modifier = Modifier.size(36.px)
                .styleModifier {
                    filter {
                        dropShadow(4.px, 4.px, 4.px, Color.gray)
                        contrast(40)
                    }
                },
            src = icon,
        )
        Column(
            modifier = Modifier.fillMaxWidth(), verticalArrangement = Arrangement.Top
        ) {
            P(
                attrs = Modifier
                    .fillMaxWidth()
                    .textAlign(textAlign = TextAlign.Center)
                    .fontSize(15.px)
                    .margin(topBottom = 4.px)
                    .color(Colors.White)
                    .fontWeight(FontWeight.Bold)
                    .opacity(80.percent)
                    .toAttrs()
            ) {
                Text(title)
            }
            Box(
                modifier = Modifier
                    .fillMaxWidth()
                    .minHeight(2.px)
                    .color(Colors.White)
                    .backgroundColor(Colors.White)
            )
        }


        P(
            attrs = Modifier
                .fillMaxWidth()
                .textAlign(textAlign = TextAlign.Center)
                .fontSize(18.px)
                .color(Colors.White)
                .fontWeight(FontWeight.Bold)
                .margin(top = 8.px)
                .toAttrs()
        ) {
            Text("$say")
        }
    }
}

@OptIn(ExperimentalComposeWebApi::class)
@Composable
fun NumberRow(title: String, icon: String, path: String, say: Int) {
    Link(modifier = Modifier
        .fillMaxWidth()
        .textDecorationLine(TextDecorationLine.None)
        .onClick {
            //it.preventDefault()
                 if (say == 0){
                     AppData.summaryViewModel.toastErrorText = "sumnum_row".tr().Sprintf(title)
                 }

    },
        path = if(say == 0) "" else "/liste/$path"

    ){
        Column(modifier = NumRowStyle.toModifier()
            .fillMaxWidth()
            .margin(bottom = 4.px)
            .padding(leftRight = 4.px),
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            Row(
                modifier = Modifier
                    .padding(leftRight = 8.px)
                    .fillMaxWidth(),
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.Start
            ) {
                Image(
                    modifier = Modifier.size(26.px)
                        .margin(right = 10.px)
                        .styleModifier {
                            filter {
                                dropShadow(1.px, 2.px, 2.px, AppColors.TextColor)
                                //contrast(10)
                            }
                        },
                    src = icon,
                )
                P(
                    attrs = Modifier
                        .fillMaxWidth()
                        .margin(4.px)
                        .textAlign(textAlign = TextAlign.Start)
                        .fontSize(15.px)
                        .color(AppColors.TextColor)
                        .fontWeight(FontWeight.Medium)
                        .toAttrs()
                ) {
                    Text(title)
                }

                Spacer()
                P(
                    attrs = Modifier
                        .fillMaxWidth()
                        .margin(4.px)
                        .textAlign(textAlign = TextAlign.End)
                        .fontSize(16.px)
                        .color(AppColors.TextColor)
                        .fontWeight(FontWeight.Medium)
                        .toAttrs()
                ) {
                    Text("$say")
                }
            }
            VDivider()

        }
    }

}



@Composable
fun ClickableSummaryCard(path: String, title: String, content: @Composable ColumnScope.() -> Unit) {
    Link(
        modifier = Modifier.textDecorationLine(TextDecorationLine.None),
        path = path
    ){
        Column(
            modifier = StaggeredItemStyle
                .toModifier()
                .then(ClickableSummaryCardstyle.toModifier()),
            verticalArrangement = Arrangement.Top,
            horizontalAlignment = Alignment.CenterHorizontally

        ) {
            P(
                attrs = Modifier
                    .id("title")
                    .fillMaxWidth(90.percent)
                    .textAlign(textAlign = TextAlign.Center)
                    .margin(10.px)
                    .padding(topBottom = 4.px, leftRight = 10.px)
                    .fontSize(16.px)
                    .fontWeight(FontWeight.Bold)
                    .color(AppColors.TextColor)
                    .backgroundColor(AppColors.GrayDark.copyf(alpha = 0.3f))
                    .borderRadius(r = 10.px)
                    .boxShadow(1.px, 2.px, blurRadius = 4.px, color = Colors.Black.copyf(alpha = 0.3f))
                    .toAttrs()
            ) {
                Text(title)
            }
            content()
        }
    }



}