package com.kelimesoft.cowmaster.pages

import androidx.compose.runtime.*
import com.kelimesoft.cowmaster.components.*
import com.kelimesoft.cowmaster.models.Screens
import com.kelimesoft.cowmaster.pages.eventlist.AddBulkEvent
import com.kelimesoft.cowmaster.pages.eventlist.EventGroupChart
import com.kelimesoft.cowmaster.pages.eventlist.EventListView

import com.kelimesoft.cowmaster.styles.MainPageStyle
import com.kelimesoft.cowmaster.viewmodals.AppData
import com.kelimesoft.cowmaster.components.AppCalendarView
import com.kelimesoft.cowmaster.components.BottomNavigation
import com.kelimesoft.cowmaster.components.CardView
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.css.PointerEvents
import com.varabyte.kobweb.compose.css.ScrollBehavior
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px

@Page("/durumliste")
@Composable
fun EventPage() {
    AppData.appViewModel.activeScreens = Screens.Events
    Router()
}


@Composable
fun EventsList() {
    val breakpoint = rememberBreakpoint()
    val eventListVM = AppData.eventListVM
    var selectGrup: String by remember { mutableStateOf("all") }

    var showAddEvent by remember { mutableStateOf(false) }
    LaunchedEffect(true) {
        eventListVM.getEventList()
    }

    PageBox(
        modifier = Modifier
            .overflow(Overflow.Hidden)
            .pointerEvents(PointerEvents.Auto)
            .onClick {
                closeDropDowns()
            },
    ) {

        Column(
            modifier = MainPageStyle.toModifier()
                .overflow(Overflow.Hidden),
            verticalArrangement = Arrangement.Top,
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            Row(
                modifier = Modifier.fillMaxSize()
            ) {
                if (breakpoint >= Breakpoint.SM) {
                    LeftPane(breakpoint)
                }
                Column(
                    modifier = Modifier
                        //.fillMaxSize()
                        .fillMaxWidth(if (breakpoint > Breakpoint.SM) 44.percent else 100.percent)
                        .minWidth(if (breakpoint > Breakpoint.SM) 320.px else 260.px)
                        .maxWidth(if (breakpoint > Breakpoint.SM) 560.px else 1000.px)
                        .thenIf(breakpoint <= Breakpoint.SM) {
                            Modifier
                                .overflow(overflowX = Overflow.Hidden, overflowY = Overflow.Auto)
                                .fillMaxHeight()
                                .scrollBehavior(ScrollBehavior.Smooth)
                                .styleModifier {
                                    property("scrollbar-width", "thin")
                                }
                        }
                        .thenIf(breakpoint > Breakpoint.SM) {
                            Modifier.overflow(Overflow.Hidden)
                        }
                        .padding(leftRight = 4.px)
                ) {
                    AppCalendarView(modifier = Modifier.margin(6.px), eventListVM)
                    if (breakpoint > Breakpoint.SM && eventListVM.eventList.isNotEmpty()) {
                        CardView(modifier = Modifier.margin(6.px)) {
                            EventGroupChart(eventListVM, selectGrup) {
                                if (selectGrup == it) {
                                    selectGrup = "all"
                                } else {
                                    selectGrup = it
                                }

                            }
                        }
                    }


                    if (breakpoint <= Breakpoint.SM) {
                        EventListView(
                            modifier = Modifier.fillMaxWidth()
                                .margin(left = 4.px)
                                .padding(top = 6.px, left = 6.px),
                            breakpoint,
                            eventListVM,
                            selectGrup,
                            onAddEvent = {
                                showAddEvent = true
                            }
                        )
                    }

                }
                if (breakpoint > Breakpoint.SM) {
                    Box(modifier = Modifier.width(2.px)) { }
                    EventListView(
                        modifier = Modifier.fillMaxWidth(60.percent).margin(top = 6.px, left = 6.px),
                        breakpoint,
                        eventListVM,
                        selectGrup,
                        onAddEvent = {
                            showAddEvent = true
                        }
                    )
                } else {
                    Box(modifier = Modifier.width(4.px)) { }
                }

            }

            if (breakpoint < Breakpoint.SM) {
                BottomNavigation(breakpoint)
            }
        }

        if (showAddEvent) {
            DialogView {
                AddBulkEvent(eventListVM) {
                    showAddEvent = false
                }
            }
        }
    }
}



@Composable
fun EventsListFake() {
    val breakpoint = rememberBreakpoint()
    Column(
        modifier = MainPageStyle.toModifier(),
        verticalArrangement = Arrangement.Top,
        horizontalAlignment = Alignment.CenterHorizontally) {
        Row(
            modifier = Modifier.fillMaxSize()
        ) {
            if (breakpoint >= Breakpoint.SM){
                LeftPane(breakpoint)
            }
            NoContentYet("Durum Yönetim modulü yakında etkinleştirilerilecektir, lütfen birkaç gün içinde tekrar kontrol ediniz.")

        }

        if (breakpoint < Breakpoint.SM){
            BottomNavigation(breakpoint)
        }

    }
}

